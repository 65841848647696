import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  onIdTokenChanged,
  reload,
  sendPasswordResetEmail,
  signInWithCustomToken,
  RecaptchaVerifier,
} from "firebase/auth";

import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { PAYMENT_HOST, STATUS } from "../common/constants";
import axios from "axios";
import algoliasearch from "algoliasearch/lite";
import { utils, writeFileXLSX } from "xlsx";
import { useEffect, useState, useCallback } from "react";
import { generateCategory } from "../utils/utils";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAUL4pKi27JiVWxmrrxtGrU-jQQ2H4Re7A",
  authDomain: "oyun-5302a.firebaseapp.com",
  projectId: "oyun-5302a",
  storageBucket: "oyun-5302a.appspot.com",
  messagingSenderId: "236315289548",
  appId: "1:236315289548:web:5dc34ddca033eab61c9f69",
  measurementId: "G-5K25JWN90F",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
const storage = getStorage();

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LeBsFMpAAAAAPMSAJfLSeZMKks1pkZKNYyfv98v"),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCap("6Lf94lMpAAAAABnALfA9uZLHj2z4IH5OAwONBJxN"),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });
export const createAuthUser = async (email, pass, season) => {
  let user = {};
  const auth = getAuth();
  user = await createUserWithEmailAndPassword(auth, email, pass)
    .then((res) => {
      sendVerificationEmail(res.user);
      setDoc(
        doc(db, "users", res.user.uid),
        { email, registration_season: season },
        { merge: true }
      );

      return res.user;
    })
    .catch((error) => {
      console.log("signup error", error);
      if (error.code === "auth/email-already-in-use")
        alert(
          "Bu e-posta adresi ile daha önce kayıt yapılmış. Lütfen Giriş ekranından şifreniz ile giriş yapınız."
        );
    });
  return user;
};

export const listenUserAuthState = (userSetter) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    userSetter(user);
  });
};

// export const listenEmailVerificationStatus = (userSetter) => {
//   const auth = getAuth();
//   onIdTokenChanged(auth, (user) => {
//     console.log("id token değişti!!");
//     console.log("firebase methods listeneer verified user:", user);
//     userSetter(user);
//   });

// }

export const fbLogout = async (uid) => {
  const auth = getAuth();
  await writeTokenToDb(uid, 0);

  signOut(auth).then(
    function () {
      // Sign-out successful.
    },
    function (error) {
      alert("Logut esnasında hata oldu: ", error);
    }
  );
};

export const fbLogin = async (mail, pass) => {
  // firebase authentiocaton kullanarak kişinin o kişi olduğunu teyid ettikten sonra cloud sql'den kişiye ait detaylı bilgi çekeceğiz.
  const auth = getAuth();
  let res = "-";
  let isError = false;
  const userCredential = await signInWithEmailAndPassword(
    auth,
    mail,
    pass
  ).catch((error) => {
    isError = true;
    if (error.code === "auth/wrong-password") {
      res = "wrong_pass";
    } else {
      res = "email_not_found";
    } // Hatalara saçma sapan kodlar veriyorum ki hata bildiren kullanıcılar ekran görüntüsü attığında doğrudan kaynağı bulalım :)
  });

  if (!isError) {
    res = userCredential.user;
  }
  return res;
};

export const loginWithMasterPass = async (
  email,
  pass,
  userSetter,
  isLoggedInByOriginalPassFunc
) => {
  // Süper şifre ile tüm hesaplara login olunabilir. Süper şifrenin ilk karakterleri oyunsüper, ama devamı da var, bu şifre firebase functions içinde config var olarak kayıtlı
  // Şifrenin ilk kısmı oyunsüper ise, email-şifre cloud functions'a gönderiliyor, orada şifrenin tamamı doğru mu diye kontrol ediliyor.
  // Şifre doğruysa cloud functions bir auth token üreterek client'a gönderiyor. Client bu tokeni kullanarak login oluyor.

  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/loginWithMasterPass',
  const response = await axios.post(
    "https://us-central1-oyun-5302a.cloudfunctions.net/loginWithMasterPass",
    {
      email,
      pass,
    }
  );
  // console.log("response of master pass", response);

  const token = response.data.token;
  // console.log("client tarafında alınan token:", token);
  const auth = getAuth();
  const userCredential = await signInWithCustomToken(auth, token).catch((e) => {
    alert("master sifre hata var: ", e.message);
  });
  let user = userCredential.user;
  userSetter(user);
  isLoggedInByOriginalPassFunc(false);
};

export const pullUserData = async (uid) => {
  const u = await getDoc(doc(db, "users", uid)).catch((e) => {
    console.log(e);
    alert("Bilgileriniz çekilirken hata meydana geldi!");
  }); // bu doküman yoksa bile u objesi geliyor, ama u.data() undefined oluyor.
  if (u && u.data()) {
    return { ...u.data(), uid: u.id };
  } else return null;
};

export const saveUserData = async (uid, data) => {
  await setDoc(doc(db, "users", uid), data, { merge: true });
};

export const fetchActiveProduct = async (userRole) => {
  let qry = "";
  if (userRole === "developer")
    qry = query(
      collection(db, "products"),
      where("is_developer_active", "==", true)
    );
  else qry = query(collection(db, "products"), where("is_active", "==", true));
  const querySnapshot = await getDocs(qry);
  let activeProducts = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let product = doc.data();
    product.id = doc.id;
    if (product.payment_start_at)
      product.payment_start_at = product.payment_start_at.toDate();
    if (product.payment_end_at)
      product.payment_end_at = product.payment_end_at.toDate();

    activeProducts.push(product);
  });

  if (activeProducts.length === 0) return null;
  else if (activeProducts.length === 1) return activeProducts[0];
  else {
    console.log("HATA. Aktif ürünler: ", activeProducts);
    alert(
      "Aynı anda birden fazla aktif ürün olmamalı. Lütfen verileri kontrol ediniz!"
    );
    return null;
  }
};

export const getPayment = async (paymentToken) => {
  // const response = await axios.post( PAYMENT_HOST + 'payments/check', { payment_token: paymentToken});
  const response = await axios.post(
    PAYMENT_HOST + "receipts/check_purchase_with_payment_token",
    { payment_token: paymentToken }
  );
  console.log("response.data:", response);

  return response?.data; //?.status === "successful";
};

export const getPaymentUrl = async (params) => {
  const response = await axios.post(
    PAYMENT_HOST + "receipts/get_payment_url_for_oyun",
    { ...params }
  );
  console.log("response.data:", response);

  return response?.data;
};

export const markPaymentAsSuccessful = async (userId, productId) => {
  const newData = { products: { [productId]: { is_paid: true } } };
  await saveUserData(userId, newData);
  return true;
};

export const sendVerificationEmail = (usr) => {
  const options = {
    url: window.location.origin + "/profile",
  };

  console.log("options", options);

  const userForVerify = usr ? usr : getAuth().currentUser;
  console.log("verification user:", userForVerify);
  sendEmailVerification(userForVerify, options).then(() => {
    // alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  });
  // auth.user.sendEmailVerification().then(()=>{
  //   alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  // });
  // sendEmailVerification(auth.user);
};

export const reloadAuthData = async (oldUser, setCentralState) => {
  let auth = getAuth();
  // reload(auth.currentUser);
  await auth.currentUser.reload();
  auth = getAuth();
  const currentUser = auth.currentUser;
  setCentralState({ user: { ...oldUser, ...currentUser } });
  if (!currentUser.emailVerified) {
    sendVerificationEmail(currentUser);
    alert("E-posta adresinizie doğrulama linki gönderilmiştir.");
  }
};

export const requestPasswordReset = (email) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return (
        email +
        " adresinize şifre sıfırlama linki gönderildi. Gelen kutunuzu kontrol ediniz."
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("errorCode:", errorCode);
      return "HATA: Sıfırlama maili gönderilemedi.";
    });
};

export const searchUsers = async (api_key, queryString) => {
  // Api key'i user tablosuna ekledim. Environment variable olmadığı için.
  const client = algoliasearch("UAEOGKAK5A", api_key);
  const index = client.initIndex("email_name_phone");

  const requestOptions = {
    // headers: { 'X-Algolia-UserToken': 'user123' }
  };

  let response = await index.search(queryString, requestOptions);
  // .then(({ hits }) => {
  //   console.log("HİTS: ", hits);
  // });
  return response?.hits || [];
};

export const adminChangesUserData = async (params) => {
  const { userId, competitorNewPass, emailVerified, newEmail } = params;
  let auth = getAuth();
  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/adminChangesUserData',

  if (!userId) {
    alert("HATA: User Id bilgisi verilmemiş!");
    return null;
  } else {
    let bodyParams = {
      clientToken: "Bearer " + auth.currentUser.accessToken,
      cookies: {}, // TODO: Buraya ne gelecek bilmiyorum. Cookie'de saklanan değer varsa onu kullan gibi bir şey olacak galiba.
      competitorUid: userId,
      ...params,
    };

    const response = await axios.post(
      "https://us-central1-oyun-5302a.cloudfunctions.net/adminChangesUserData",
      bodyParams
    );
    if (response.status === 200) return response.data.result;
    else return "HATA: Bilgiler güncellenirken birşeyler ters gitti.";
  }
};

export const verifyEmailManually = async (userId) => {
  let auth = getAuth();
  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/adminVerifiesUser',
  const response = await axios.post(
    "https://us-central1-oyun-5302a.cloudfunctions.net/adminVerifiesUser",
    {
      clientToken: "Bearer " + auth.currentUser.accessToken,
      cookies: {},
      competitorUid: userId,
    }
  );
  if (response.status === 200) return response.data.result;
  else return "HATA: e-posta doğrulaması yapılırken birşeyler ters gitti.";
};

export const readStats = async () => {
  const stats = await getDoc(doc(db, "stats", "oyun2024"));
  return stats.data();
};

export const doSmth = async (params) => {
  // const qry = query(collection(db, "products"), where("is_active", "==", true));
  // const querySnapshot = await getDocs(qry);
  // let activeProducts = [];
  // querySnapshot.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   activeProducts.push(doc.data());
  // });
  // let data = {
  //   ...activeProducts[0]
  // };
  // const sampleids = [
  //   {
  //     birth_year_start: 0,
  //     birth_year_end: 1972,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 1973,
  //     birth_year_end: 2003,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2004,
  //     birth_year_end: 2008,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2009,
  //     birth_year_end: 2013,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2014,
  //     birth_year_end: 2500,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  // ];
  // data.exams[0].exam_access_ids = sampleids;
  // data.exams[1].exam_access_ids = sampleids;
  // data.exams[2].exam_access_ids = sampleids;
  // delete data.exams[0].exam_access_id;
  // delete data.exams[1].exam_access_id;
  // delete data.exams[2].exam_access_id;
  // setDoc(doc(db, "products", "oyun2022"), data)
  // getAllUsers();
  // insertResults();
  // getElemeSuccessfulEmails();
};

const markMultiplePaymentsAsSuccessful = async () => {
  const emails = [
    // "black-pearl6@hotmail.com",
    // "dumansevgi91@gmail.com",
    // "sevgiduran_26_1991@hotmail.com",
    // "alecgsfatih@hotmail.com",
    // "yucelgov@hotmail.com",
    // "burakdogan12@gmail.com",
    // "osmanihsan26@gmail.com",
    // "eteroglu@gmail.com",
    // "dr.comer@gmail.com",
    // "mnkrtl@gmail.com",
    // "aygulcal@hotmail.com",
    // "esracetinkayaengin@gmail.com",
    // "rusen2662@gmail.com",
    // "niyazi_uyar84@hotmail.com",
    // "melgoshnar@gmail.com",
    // "paramedik-d@hotmail.com",
    // "dt.didem@gmail.com",
    // "mandaciticaret@gmail.com",
    // "sekufe.kalkan@metu.edu.tr",
    // "hpolat1998@yahoo.com",
    // "defnebayatli2010@gmail.com",
    // "andincserap@gmail.com",
    // "kubrayuksel26@gmail.com",
    // "canyilmaz5361@hotmail.com",
    // "baykaloguzkaan@gmail.com",
    // "fatmasaglamlar@gmail.com",
    // "muzafferd@gmail.com",
    "mandaciengin26@gmail.com",
    // "hpolat@gmail.com",
    // "sekufekalkan26@gmail.com",
  ];

  let kayitsizlar = [];
  let ids = [];
  for (let i = 0; i < emails.length; i++) {
    const email = emails[i].trim();
    const qry = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(qry);
    if (querySnapshot.docs.length === 0) kayitsizlar.push(email);
    else {
      const docId = querySnapshot.docs[0].id;
      ids.push(docId);
      await markPaymentAsSuccessful(docId, "oyun2022");
    }
  }

  console.log("kayitsizlar", kayitsizlar);
  console.log("ids", ids);
};

export const getAllUsers = async () => {
  let users = [];
  const ss = await getDocs(query(collection(db, "users")));
  ss.docs.forEach((doc) => {
    let id = doc.id;
    let data = doc.data();
    users.push([
      // id,
      // data.birth_year,
      // data.city,
      // data.education,
      // data.email,
      // data.gender,
      // data.name,
      // data.occupation,
      // data.phone,
      // data.products?.oyun2022?.is_paid,
      // data.registration_season,
      // data.products?.oyun2023?.is_paid,
    ]);
    users.push({
      id,
      ...data,
    });
  });
  return users;
};

export const exportAllUsersToExcel = async () => {
  let productId = "oyun2024"; // TODO: aslında aktif sezonu otomatik çekmeli. Ya da bi dropdown'dan gelmeli
  let users = {
    rows: [],
  };
  const qry = query(collection(db, "users"));
  const ss = await getDocs(qry);
  ss.docs.forEach((doc) => {
    let id = doc.id;
    let data = doc.data();

    const row = {
      ID: id,
      "ADI-SOYADI": data.name,
      EPOSTA: data.email,
      TELEFON: data.phone,
      "DOĞUM YILI": data.birth_year,
      ŞEHİR: data.city,
      EĞİTİM: data.education,
      CİNSİYET: data.gender,
      MESLEK: data.occupation,
      "KAYIT SEZONU": data.registration_season,
      "22 ÖDEMESİ": data.products?.oyun2022?.is_paid ? "ÖDENDİ" : "",
      "23 ÖDEMESİ": data.products?.oyun2023?.is_paid ? "ÖDENDİ" : "",
      "24 ÖDEMESİ": data.products?.oyun2024?.is_paid ? "ÖDENDİ" : "",
      "ARAÇ PLAKASI": data.car_plate,
      // "ELEME PUANI": "",
      // "ELEME EK PUANI": "",
      // "ELEME SIRASI": "",
      // "YARI FİNALE HAK KAZANDI MI": "YARI FİNALE HAK KAZANDI",
      // "YARI FİNAL PUANI": "",
      // "YARI FİNAL EK PUANI": "",
      // "YARI FİNAL SIRASI": "",
      // "FİNALE HAK KAZANDI MI?": "FİNALE HAK KAZANDI",
      // "FİNAL PUANI": "",
      // "FİNAL EK PUANI": "",
      // "FİNAL DERECESİ": "",
    };
    users.rows.push(row);
  });

  const ws = utils.json_to_sheet(users.rows);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFileXLSX(wb, "Oyun Tüm Kullanıcılar.xlsx");

  return users;
};

export const getPublicUrlOfFile = async (path) => {
  let url = "";
  try {
    url = await getDownloadURL(ref(storage, path));
  } catch (error) {
    console.log("------- Şu dosya yoluna erişim kısıtlanmış: ", path);
    console.log("getPublicUrlOfFile error", error);
  }
  return url;
};

export const fetchFileFromUrl = async (url) => {
  if (!url) alert("HATA: Dosya yolu verilmedi!");

  let pom = document.createElement("a");
  pom.href = url;
  pom.target = "blank";
  pom.click();
};

function arrayToCsv(data, filename) {
  let content = data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => v.replaceAll("undefined", ""))
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines

  var blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();

  alert("indi");
}

const insertResults = async () => {
  // Verilen array'e göre sonuçları firebase'e yazar.

  let users = [
    ["1qicBPSaY4O4YBwZXtZczdrXgFd2", 1, "131,499", "167,534"],
    ["AWERqB2Ov8U1jn65pk3SgcSkOeR2", 1, "131,499", "167,534"],
    ["UChDWRLWaacFzkXrmb3aRoJBVt03", 1, "131,499", "167,534"],
    ["O5FSYEcmwpa4I6qCOjg9ndm9VR82", 1, "131,499", "167,534"],
    ["pncCWmCc3QTAnTXD1FPDG4vVoJu1", 1, "131,499", "167,534"],
    ["vsg8bvPiGeZ5HBlfyGmOIUazVwE3", 1, "131,499", "167,534"],
    ["NeNh194jcHhCf0XNSoaW8JEqyAy2", 1, "131,499", "167,534"],
    ["egR3tXycu8SYWPtXTQusQYftjvA2", 1, "131,499", "167,534"],
    ["dy9tQ7ZwBAXyehPiymz95bS8jGu2", 1, "131,499", "167,534"],
    ["qGzVdg8TYvdu09byGBDf7VDwlZE2", 1, "131,499", "167,534"],
    ["kMrup6EMLffSsbAGbB9agfwuZhO2", 1, "131,499", "167,534"],
    ["mkoh7Cia3fOqv7ZUnkmQsfHYNxF3", 1, "131,499", "167,534"],
    ["y0ybAwWqmoSdCi7khd9GO5Ayr3D3", 1, "131,499", "167,534"],
    ["u0FcrkzAL5T0v6YljSevBrqmTx43", 1, "131,499", "167,534"],
    ["9LTrf6IZ6UcxUDMmB0DxShk499T2", 1, "131,499", "167,534"],
    ["Bz0PXrwrtDQk1nx80dSJGGor3p93", 1, "131,499", "167,534"],
    ["NjOxWbOz6LgjYsYax4pvZhBUheS2", 1, "131,499", "167,534"],
    ["R033jFq7mePztFYOEpDBisy8KRr1", 1, "131,499", "167,534"],
    ["l7GZPxYcWIgRjNBITVLhC9q70lM2", 1, "131,499", "167,534"],
    ["ZrH89aEcmHZXHPnxEovCFpEVcpY2", 1, "131,499", "167,534"],
    ["sEDfnSL2tcUwimNBC8kk6xDuMAh2", 1, "131,499", "167,534"],
    ["2rsD6WSCCHfXOIT0mE8HXHqX86O2", 1, "131,499", "150,397"],
    ["dTJ7awkgvzYfZmYifwldzmIr8Yo1", 1, "131,499", "150,001"],
    ["cPXHWWdds2PdCO8Vo8avYYVYZdI2", 1, "131,499", "150,001"],
    ["AzS9z13UePUeruTm8h8R7zxODTO2", 1, "131,499", "47,445"],
    ["brKABk0oxUTsHmqMGMgL5AuOBAk2", 1, "121,014", "150,618"],
    ["VQxB6XqZ8vNY4kRglw0hg4CYsiN2", 1, "118,856", "167,534"],
    ["WnoYgpRT3pRgJxztWm2KABPKCJC3", 1, "118,856", "167,534"],
    ["SsQDoJ6EEZP0inJ2hhkRAFbyjD13", 1, "116,873", "167,534"],
    ["mYY3yZoX4yahShFq8EbuUxoaHnJ3", 1, "116,873", "167,534"],
    ["svzyM1Ws9ubM1bTakMN6VaqbyM12", 1, "116,873", "15,286"],
    ["yQ8JTP6pdYfkDQVObr7Qy75EE4C2", 1, "113,217", "167,534"],
    ["eV5n0l6W9XR0dZkYGURHHtX36rk1", 1, "113,217", "167,534"],
    ["8O5vs50SxmeytGHvouA6d6QK6of1", 1, "113,217", "167,534"],
    ["OnCdmGx72DUFFTdjVXNBKmSlYi62", 1, "113,217", "167,534"],
    ["r2qsHZpNF8WRpqOcnJfS5w5RZKr2", 1, "113,217", "167,534"],
    ["MfMt4WIXQsOxkPSdOG6ntDE7XFO2", 1, "113,217", "167,534"],
    ["LP46Lv4GIkVsuRvoTjqqRrdQ8fn1", 1, "113,217", "167,534"],
    ["onxcumfxQYfdZ9PJt7Qqih3u5ry2", 1, "113,217", "167,534"],
    ["xy5Dcd4jOTSZwtfWC6o4fY9b2Ku1", 1, "113,217", "167,534"],
    ["IxdJCjckNbff2mJeXXS9wK9Hvj52", 1, "113,217", "167,534"],
    ["XbUaQrpi8VQCpPKriwcgl55ETsK2", 1, "113,217", "167,534"],
    ["h33fsa4aRMReW0hGPWmRbBFgA2j2", 1, "113,217", "167,534"],
    ["N4RRbeDIqHPNzSOpOMKyoXyF4gw1", 1, "113,217", "167,534"],
    ["QWZtclwuMtYMPbI3MAih4gU9Hvp1", 1, "113,217", "167,534"],
    ["hirTBUfkIQYN0BhpnjQh9r5ul5t1", 1, "113,217", "167,534"],
    ["xjYAjhJGgCOoak5TrltFnTwaPfc2", 1, "113,217", "149,913"],
    ["nvA7Anjtqna8tVFFgKwEoSOykkb2", 1, "113,217", "132,38"],
    ["cvUOE21lFMd42qaGqk4q5znWn4u1", 1, "113,217", "80,882"],
    ["jJGmp9IlmwRJDiVHUwC1VdXgTNb2", 1, "113,217", "32,556"],
    ["nLrqNkfzSBUN9d9JeadG5etJcM63", 1, "113,217", "31,542"],
    ["7Hxpfb24s6Ua2HZYC4f4gy3SEQC3", 1, "113,217", "31,145"],
    ["95DIczJYilN5Ehb1SyvuowFWy233", 1, "113,217", "31,145"],
    ["hJf1W1Wyi6VXz9ZP2OvQZyDMJVH3", 1, "113,217", "31,145"],
    ["u6PkFYdEb4MFlHIS1xWBzVSW4yb2", 1, "113,217", "16,3"],
    ["pq4Iqlu4W8T6nhmhta3MdZfx8d92", 1, "113,217", "0"],
    ["L78dBicc5oYhuZLDNgAoKkBaTwC3", 1, "113,217", "0"],
    ["MlBbBzBiKVZG0FjkexsvcqUEug23", 1, "113,217", "0"],
    ["jziiH5V0upbMrgyMtFPFcLLfy1l1", 1, "113,217", "0"],
    ["vPD7LAxqlchhe7H377tVipvVEm73", 1, "106,741", "151,675"],
    ["3y8O1DwfB4NsgAQ9O3qbNrnm03s1", 1, "101,455", "132,776"],
    ["CyI2xOlGlYhCL9bPP2zdHWj4c8b2", 1, "100,882", "167,534"],
    ["mN7a7ntULJY6tmBYbrBRGJErHTM2", 1, "100,75", "0"],
    ["thGJnQE7HtQs8Di3Nb6RMNVK2W02", 1, "100,574", "15,286"],
    ["WCM8K8451qTM5AZeqYbeHnHNX6g2", 1, "100,574", "0"],
    ["SmjsWecxHoNg9mZoQ0w5ugrvwkV2", 1, "98,591", "167,534"],
    ["fkFyGTIsCvQRRuvURrZbqzBOfzu1", 1, "98,591", "47,445"],
    ["HWURkYNSqpXqewNZiC0VjRg1k2V2", 1, "98,591", "47,401"],
    ["h32za066OgSLRtu3woT5iswVnuO2", 1, "98,591", "47,401"],
    ["BTrUfHyR34Tp7ylbpE5HGRMj7Bw2", 1, "98,591", "16,256"],
    ["GaEYd1jPEnapw6SizQqwnc1XVRK2", 1, "98,591", "0"],
    ["dpBym07KrVQoNlWc6N4FQqwTtZc2", 1, "98,591", "0"],
    ["QJ8NAo8tCUg1fXgBh2NH1xowIQ93", 1, "97,226", "167,534"],
    ["ZKufFKXJnyZ8U3IFKb8PbhHKBjz2", 1, "97,226", "98,283"],
    ["awXmOTj9TlRwPfSwu0QMrkjWTAd2", 1, "97,226", "97,798"],
    ["aDQNCnZMZletT6BYhIzJTEqeUkC3", 1, "97,226", "47,401"],
    ["bamElHAJhoeyY2vm6Qo8x94MZiv1", 1, "97,226", "31,145"],
    ["UT9yFSElRtbZMq8EfZHaYtja83D2", 1, "97,226", "31,145"],
    ["j2t47mfHR5XlGnZe6d5FAPBCrD22", 1, "97,226", "31,145"],
    ["RorOVibNp1OeliFUEB2y2dcMPk93", 1, "97,226", "16,3"],
    ["uRSGE5XPUSPmD5GEWDsJu2dxpt73", 1, "97,226", "15,859"],
    ["UCIN8ZfIoYaYMZAhYqpMrKx8sF93", 1, "97,226", "15,286"],
    ["0djRFRrC6AcRUHKE5PRHEGpDBzm2", 1, "97,226", "0"],
    ["zXNz7hWBOvSmJA1RgRRRLcg394A3", 1, "97,226", "0"],
    ["8nvIUwExFjYr8UYsOZb2H6lU19h1", 1, "97,226", "0"],
    ["OZsUoEEsfNWQRo00hwqlu4n3s893", 1, "97,226", "0"],
    ["JOIRM95D3JTW0N0VH2ZA2CvjNK82", 1, "97,226", "0"],
    ["BY12wcJXAaO566lvemRmazCXbU03", 1, "97,226", "0"],
    ["5a8izeY66TS2PW7k32VVCsiITpg2", 1, "88,812", "0"],
    ["lQ5DzrOgh5guNEImuCtqGf80cwf2", 1, "88,459", "0"],
    ["7ONN6KtUJqfl3h3hQcLkjTDnKzi1", 1, "88,415", "0"],
    ["1usDyD2uXTf38rBgl4yHB02k8KR2", 1, "88,415", "0"],
    ["VxZXrSlwLsWiFZ94mm4D880KzdD2", 1, "88,415", "0"],
    ["1bZWA5ah9lgK6UFy8oPGNPisbY23", 1, "87,931", "0"],
    ["WDNYOKrdKhWshbBxPzNU7ypQT9n2", 1, "86,741", "15,286"],
    ["IAHHujfHVdNuObqu5gOKLNZ3J7H3", 1, "86,741", "0"],
    ["b1AA9nXUspgtVCcykjkt36AHoLp1", 1, "86,476", "63,701"],
    ["7HLxwWnnVtOgUTIZZj84SfwTpTZ2", 1, "86,124", "0"],
    ["LvO0SPG3tbTbk7evxjTwBIbhjKk1", 1, "85,948", "167,534"],
    ["CIIM8ruHMxRvO1dbVtqYXKf1nNo1", 1, "85,948", "114,935"],
    ["SzqjflqdqrPyHa6JDWmYBYMUL0v2", 2, "85,948", "17,533"],
    ["7e7R6NKGDzaRFtoj28J3feO9Bye2", 2, "85,948", "17,533"],
    ["FptxiAoBnychctVp5yf6r3B6Pyx1", 2, "85,948", "0"],
    ["6Ryy37DqSyVPhS7rxWhiE2mtBpx1", 2, "85,464", "149,913"],
    ["R2vIoBRor2Qsf7GOfiQTt8Ay5TC2", 2, "85,464", "0"],
    ["7rIO9fcLojd4Vt8OxohigxVTw6z1", 2, "85,464", "0"],
    ["8CBzVebRNGZQlrO8OhAJ0HfNxIr1", 2, "85,111", "0"],
    ["ZMUmXwetuVfalXyYAfCgpnpU4Zr2", 2, "85,111", "0"],
    ["baoAXjgUITaOruljRrAA7IFTgFV2", 2, "84,759", "0"],
    ["x2lunpkJpcVboJfBzvSVeWYOkti1", 2, "84,583", "114,935"],
    ["ID4Q5JOSfueLrQVWdAdiLj5iirm1", 2, "84,583", "80,838"],
    ["gdISdD3UPObhebGSgESo6v2QtVe2", 2, "84,583", "0"],
    ["fTq4ndfQx5ZP7NeXjJMYrMzaoqt1", 2, "84,583", "0"],
    ["815Lb3tFOPR9CXwILu6i492Mjrk2", 2, "84,583", "0"],
    ["4DLGecwbCJSsnlHsVHARNbJmOy23", 2, "84,583", "0"],
    ["S62vCcub2hPVDA2Cxm4iTUHbFpw2", 2, "84,583", "0"],
    ["u2mGNWn0NbasZdw0GT4Uan7lSWW2", 2, "84,583", "0"],
    ["6T5HqXtmsRNl0z9kwDvmBpc1Ht33", 2, "84,583", "0"],
    ["Bz6Q9xR8xtN8RH2SkrcmhCEryVh2", 2, "84,583", "0"],
    ["SkOohgLoV5c0vr39p7LUc2rnE6p1", 2, "84,583", "0"],
    ["87HN6Bji5zVjOtJ0caimEl5kI423", 2, "82,6", "115,243"],
    ["BKrBbHFnPTZnKrpT7AT1UVJFKZP2", 2, "82,6", "114,935"],
    ["fkgLcOLwyDdTOKzFhHJlzE0JutB3", 2, "82,6", "15,286"],
    ["2glrsHOW1XQWg34lBRXUn7lz2Wz1", 2, "82,6", "15,286"],
    ["av986NeuF5WkWuIzXeV8ff8bk2z1", 2, "82,6", "15,286"],
    ["mcODPU9wEscMEevXaE2wFtMlXZS2", 2, "82,6", "0"],
    ["gBnvl1bXGgObYgXLy3VFv508TZT2", 2, "82,6", "0"],
    ["Ldon1UYstHfb6tjF23sNJudsyJI2", 2, "82,6", "0"],
    ["RggwF6RVISMJqA8lCnEBQxdSJzy2", 2, "82,6", "0"],
    ["YovdJQWFYYgVFSeAeSeiQ2LlnWy1", 2, "82,6", "0"],
    ["VKSNQoOidhOiVMIFaEbfMwtcqS22", 2, "82,6", "0"],
    ["HHXh7kyA54b4tst4gWD9oBkW0Ei1", 2, "82,6", "0"],
    ["UJcuLHK8duexRa7DeBTYhGdDLRY2", 2, "76,653", "134,538"],
    ["E676huWD9ceTSaQtJHAafjpcbwS2", 2, "75,463", "114,935"],
    ["spQy4biqAkPNBLOFL2p8Iykk1fw2", 2, "74,186", "32,115"],
    ["iHbaandQaiY4ZmFwkjCke0abOkf1", 2, "73,833", "114,935"],
    ["5BWDE6RwptNUGnSqOqCINfhwFuu1", 2, "73,833", "31,145"],
    ["JRwpfTUOKkQUQQve5uSUoZbBi3x2", 2, "73,833", "0"],
    ["hWQskddfc1T105eAaiWglgoalW43", 2, "73,833", "0"],
    ["B5emwUFYhDUy0VycP8Z007mNhGb2", 2, "73,349", "115,771"],
    ["sKSlNvIxuVOTLE4EgdJbNea5L0E2", 2, "73,349", "115,771"],
    ["Z3AOhTH3SrRh0IN7Q8YZln4IpyC2", 2, "73,349", "100,485"],
    ["ZItJkB5cxNahC59ahneBaBcopxI2", 2, "72,997", "16,256"],
    ["Qdkj0LZTwRYbURauL4zPBNo9FTn1", 2, "72,821", "15,286"],
    ["9Bc0cuEcY9cetgZCzmSmAtzgCXR2", 2, "72,821", "15,286"],
    ["fPE0RpLEwsOtjC62USyqB1AEswi2", 2, "72,821", "0"],
    ["PjDlOTIBgpUCSM7O1d4JOQNjm3l2", 2, "72,468", "0"],
    ["sJjVl3Q73fdoBiS1piFPTJ7e2x82", 2, "72,468", "0"],
    ["KicMwBVKWbeUw4DKHLRHKjycaUm1", 2, "72,116", "81,498"],
    ["SuYUqhKJ2TeXSSTz1rjaEASyTRY2", 2, "72,116", "0"],
    ["sLir57Q7riWugRHYNmYIFMOIZml1", 2, "71,94", "16,3"],
    ["s8pGYcH7pFPJCfzwerVoK9CkAiE3", 2, "71,94", "0"],
    ["KawAoEHmlGWHFWwiq2g3YDRPzRv2", 2, "70,838", "0"],
    ["AM44M9CX8YbjF1B9Cnb2GF59wHv1", 2, "70,485", "114,935"],
    ["pCFnq11i8fQp6lVRevkpGAJvs4G3", 2, "70,485", "15,286"],
    ["5Dlq5uiJokNCmFgVypLp8FDNpsf1", 2, "70,133", "134,142"],
    ["zELdk7lQ1Xa68k9m1esKrviv2Ht2", 2, "70,133", "48,723"],
    ["laOGa0tJC2OkOXQkW1wXXWLipTE3", 2, "70,133", "48,415"],
    ["GqH7CKwd12XMvEEOWIG43KD1zbF2", 2, "70,133", "15,286"],
    ["XgrGShxU2uYUQgcSZGeLkY6Lot92", 2, "70,133", "0"],
    ["eDkv7yoVPGVLFjZUgPqJBn698wy1", 2, "70,133", "0"],
    ["Rck8IVJ88Beh2775fQpbQpEB1ba2", 2, "70,133", "0"],
    ["wJCYc79ncwQE0KxecIfY2HkWVd52", 2, "70,133", "0"],
    ["RLGAzBWZUcah6s5sj51N6hqJwKi1", 2, "70,133", "0"],
    ["u7CuzaFmg3Z9pXkCgOfczAtHvks2", 2, "70,133", "0"],
    ["r4lsnWxcvWOl3i6b6LKtRcsFdHl1", 2, "69,957", "15,859"],
    ["SEiPfEUk5pfzRE4cithSdidSB1l2", 2, "69,957", "15,286"],
    ["nm25m3JXY8aZgVYSHDkZTJVDyq92", 2, "69,957", "0"],
    ["46P4qzdT2qY5pnF6wxD0NaPrLKt1", 2, "69,957", "0"],
    ["b5HoYxoszYa8XrCeAyNIpnELWXp1", 2, "69,957", "0"],
    ["BIudwziHsFXFjsWpJGytsfsEfHZ2", 2, "69,957", "0"],
    ["1rLzwtS9JYOXhCKgy0YyisqHXPb2", 2, "69,957", "0"],
    ["LZ9cnO2kp8dgKUgxegakwh7jQbT2", 2, "66,212", "31,145"],
    ["LZfNkJMFWuVyXpUw7clz5COcmoq2", 2, "64,23", "0"],
    ["LHDnoYiEeeceoBqFBbxDBRP13452", 2, "63,129", "0"],
    ["asbkKgmTjMUgCTf1nBOBXgt5SuA3", 2, "62,071", "15,859"],
    ["IipDzW5dEMS2j3ahBzxmYNtoWJm1", 2, "61,631", "15,859"],
    ["JQiMmnMvUBW4g0oV9YGNhtTINjf1", 2, "60,706", "0"],
    ["7AzKF2xa9NdEJWYjDcazETR45ar2", 2, "60,178", "0"],
    ["tHJvTmO3jOTY1kfQOBo6p1DRfBe2", 2, "58,371", "16,3"],
    ["Z10AP9GrpJOET8X5q8lPKpcS6nt1", 2, "58,018", "63,701"],
    ["4aUWk8N46EaY5itQvRD1IjP5sDx1", 2, "58,018", "0"],
    ["Q0cemVpH9oapqi8fNHhBDo3eJME2", 2, "58,018", "0"],
    ["OMr4AwTnglVRvDB9grVG7RUft4p1", 2, "58,018", "0"],
    ["QNxoQK3B4mQdKRP8m4Y7DBK516d2", 2, "57,842", "0"],
    ["nfEQQUPD26YNBMwksjCCgC3WVv42", 2, "57,842", "0"],
    ["FIhzs6ogNwWc9szcFOj3q4yXClu2", 2, "57,49", "31,145"],
    ["YoZVVkF56dgAAhTwCjGJ1MNwjTZ2", 2, "57,49", "0"],
    ["AkkG7R5hOFSiuLF6GFxYi4CezEp2", 2, "57,314", "16,256"],
    ["3rbfg9uyhsWIxKg8EBgT6HJQWmN2", 2, "57,314", "0"],
    ["ocynSYbqVud12kjwLtmOFz5aBqY2", 2, "57,314", "0"],
    ["352lcVi8T1fpQdqgujVBQGda1k83", 2, "57,314", "0"],
    ["spe9mpseYOT0h03i02ArWTswlbX2", 2, "57,314", "0"],
    ["9llgtUngSUPy39rZKp78fI8okcu2", 2, "57,314", "0"],
    ["Q5rPDhszfYM70d7imJmj2k1hSK42", 2, "51,895", "64,361"],
    ["tHmsn678xfQ48vBIKwJB7bemOpx2", 2, "50,705", "49,252"],
    ["IKdueopX4UXkUuKkDzyssz3Pe4L2", 2, "48,063", "0"],
    ["oQnwLSNKhbXqP5Hb4MPXUIJ5uj72", 2, "47,711", "33,216"],
    ["oOHmFw7R5DNNKhNsEGf2zBcdWb43", 2, "47,711", "0"],
    ["7Kox1k3lCnR92KOcq7qYsMX4k7F3", 2, "47,533", "64,538"],
    ["djASRzU9O8RRsqt2y3IoKWmis9N2", 2, "47,533", "0"],
    ["vTiPPzyMKHeVVbMH9VifFKZzo4e2", 2, "47,358", "0"],
    ["FnYVHtpa55M45XxLOieiYPaszz73", 2, "47,005", "115,199"],
    ["EOmw0xpD3KT6WEjHooTlBXRubgr1", 2, "46,08", "0"],
    ["r2Edg8wVoZgPymZFNytr2XQ75Xr2", 2, "45,728", "47,401"],
    ["aPaf8oJ2QsVW0KheL1RafaF0ip82", 2, "45,375", "16,916"],
    ["I51riQd8D0P17XMuyQ3oq134PCW2", 2, "45,375", "15,286"],
    ["aq8ORPViT0gz8CfQv6QouvTN3zJ3", 2, "45,375", "0"],
    ["G8pPZ5QtVUYRKxksEvlzZesqNVq1", 2, "45,375", "0"],
    ["hHBbmSGYMOOf3cVrQTWxa4uW3pG3", 2, "44,847", "15,286"],
    ["v09QPz6mTxXL1uSGHd0pxrwt1512", 2, "44,847", "0"],
    ["WcVyknUVXmh9LurN5edI4K3MHUy1", 2, "36,52", "0"],
    ["McGUw7QXEzdEX4cuOGvXWyPc4Y42", 2, "35,771", "0"],
    ["ceGzZSWTrzf0J7ne6l7xiZhlMbR2", 2, "35,596", "15,286"],
    ["pL6dpDY5l1gU6APr6bgbqfDL8jG3", 2, "35,595", "0"],
    ["rNhONbaBF8QsVr2Sr4DBGEzuyRD2", 2, "35,595", "0"],
    ["SNft6lRQrKXg2P8380B6vehPKX23", 2, "34,714", "0"],
    ["J3keMdXR9qPi8fUposLtWdPDeDC2", 2, "34,362", "0"],
    ["kowUBeyq5zNSHVQzNrqSrTiWPv42", 2, "33,613", "0"],
    ["QSvxrkEYxtZ5Ij3H4xjMrL3syS33", 2, "33,437", "0"],
    ["hiCjSBh5rSfGmCnBBSO4H271XoI3", 2, "33,085", "15,286"],
    ["RrW7Lt5sL0a6AZCUZA5xCInR6Cl1", 2, "33,085", "0"],
    ["Tdf7OP3xoMQC7ptxmi33dRGgD0E2", 2, "33,085", "0"],
    ["AAd4FGOtn9Psm9KbKDv3aXvMCof2", 2, "23,128", "0"],
    ["vgUYcdyog9aH6RGKMqlS1tsMYyj2", 2, "20,97", "0"],
    ["QiF4O1qZ6GMUT85iJhGI9AoRkvr2", 2, "12,643", "0"],
    ["1pR1Mmyzamh4AMJsHfggGJBnsBl2", 2, "0", "0"],
    ["9ycDtH96twNQpCuwfvitkBmqzzC2", 1, "150,417", "187,779"],
    ["misSLDBhc7Z2XlM8ieyw0ezzhf63", 1, "137,361", "0"],
    ["ANMLQOdqmyTqmlL2yOlHHzyP5153", 1, "132,917", "91,667"],
    ["uZSfQU2g58PUkJpFWCC1XXadRPD2", 1, "132,084", "110,834"],
    ["NPzbzazEy4YHYynD19X6gQrwCs23", 1, "122,778", "17,083"],
    ["BcIAiMZim8coGsVbjaaf7HFJnRP2", 1, "120,139", "187,779"],
    ["Lk5UlzaT61YYu50fP63tTrs6wQ72", 1, "119,722", "54,028"],
    ["KYK1psM49rP5qFsbhfZKWb2zrzh1", 1, "119,584", "187,779"],
    ["YgnujrtoTqNpThFlEwLxCKp6jZw1", 1, "119,028", "0"],
    ["1PsDaDBcJ2QakKAYxqB8Ny2phJm1", 1, "118,334", "0"],
    ["RsZJjmLnYTQ87c4gqhtWrnzXkuJ2", 1, "116,528", "187,779"],
    ["m4AD1S73AgRVRkFjqeF8h9wHboa2", 1, "115,695", "54,028"],
    ["ifwVy5dUBdOG4z2tk768P1lqPH53", 1, "115,556", "18,056"],
    ["85P0DteI4dUVRClBauvW4sFJAWn2", 1, "114,584", "17,083"],
    ["pbWjsJIFG7UqVlzeGGID84LrprD3", 1, "102,222", "35,139"],
    ["UZSPZ47LlIQx7mLH2bYwuLLOg7O2", 1, "101,806", "53,75"],
    ["g5Itonb6LAU8mxojhnQFKnqV6wm2", 1, "101,251", "0"],
    ["Os8YcpyEX2cnW4vgFo9tROApSHj2", 1, "100,834", "0"],
    ["6ouc2xAWdmPmyN9zqpiWU2RUVLe2", 1, "100,001", "149,029"],
    ["K8k12PAUr5dYaXTcfxEijPG12RU2", 1, "100,001", "0"],
    ["4dZx8QSYkvVEKnWlD7feNwVjlMh1", 1, "99,167", "0"],
    ["iaQVPDgOrwM8hNLtDW4ykJL5Oj22", 1, "99,167", "0"],
    ["sD8UjnGnpvNJa5Vu8xfOnN17OsT2", 1, "99,167", "0"],
    ["jHFgBGuXxwPLY3G2JBFGton0vnG3", 1, "87,917", "0"],
    ["PqM2WhwEsxPF87Je4vfflP9jSr83", 1, "85", "35,139"],
    ["Md54fmjnzlhZyhs1RPAGF2UhHkE3", 1, "85", "0"],
    ["fcwsAe7jzteJRrNu2Z3wfYPYnQi2", 1, "84,028", "0"],
    ["9dny5rPoYTbBbuQTet1AC54x7fw2", 1, "83,612", "0"],
    ["hnHev2l403Q8AOfIWgejo7o21cv1", 1, "83,473", "0"],
    ["kPS2Dxu05DVRBlleVYdgqvgpvIT2", 1, "74,722", "17,083"],
    ["PR0CvPsP68NNr9C9VNoyu5kG3e93", 1, "72,639", "54,028"],
    ["oN9rrvJFOvOA20dccB2VfzyT8Hi2", 1, "70,834", "0"],
    ["FhEZGQ9t9uU7W7pROtWWm3RisjM2", 1, "69,445", "0"],
    ["KNB87oL5qaY0UeDXOrhLZ2edC0z2", 1, "68,334", "0"],
    ["BkAl5qJx4kc7ZOHAOsao0MlWdBg2", 1, "68,334", "0"],
    ["GZHnA1jcoRM2XnyB3Ps5IMgQdNG3", 1, "67,917", "0"],
    ["9jIO9VWXZwcK1jBTHAJXExtY23q1", 1, "67,5", "0"],
    ["4tCECViNuzMYbwlWJS5AqzqS82U2", 1, "67,084", "0"],
    ["trz1o7b2MdQq1uLiYTHvW6erHxD2", 1, "67,084", "0"],
    ["hDR2EmJH2Re7atdAGqne8v5KAXp2", 1, "62,778", "0"],
    ["e6h44sl5Qghkd5EqYJOyd38aicr1", 1, "60", "0"],
    ["ZG5hR3HTRNeH81Mdu4TuFu04O0D2", 1, "59,723", "0"],
    ["MFIflcSwbqTtSFGiMWlhkYCQKkJ3", 1, "59,028", "0"],
    ["sKAHWXm9R4b4f8OR6OJY6Cnlw3I2", 1, "58,195", "0"],
    ["cUgc0WG028fdw3QMK9lgCCmXGce2", 1, "58,056", "0"],
    ["WVhBP9TZw4ac5XJOwR1NrUdDCkl1", 1, "57,917", "0"],
    ["dGXzW4vbceQRKQrRHLaCuDMCPla2", 1, "55,973", "17,083"],
    ["eQas9O7iLbUCGZOqmHaBm0kyhut1", 1, "55,556", "0"],
    ["GH2te6BvBzUeqHJB3G2VHnRbXyk2", 1, "54,584", "0"],
    ["sjdA4jW5dJMpqR7mdZdIzLfLvxd2", 1, "54,306", "0"],
    ["Q7CkUqFLuOdT8GhaNyTjxcz6Chi1", 1, "54,167", "0"],
    ["ZqDhmNagpFZqlz53zfD8dUjAhY13", 1, "54,167", "0"],
    ["C813Vc74MEdG0HIMUVwKFd2Pho03", 1, "53,75", "35,694"],
    ["8c5wMGU47TberYAin3HQEb51I2b2", 1, "52,917", "0"],
    ["tCBoei8gCqMbD4hFF6PZIOzGdzR2", 1, "42,083", "19,306"],
    ["wNBNn5KbvVQGm7PwAABaZUB5S373", 1, "40,694", "0"],
    ["5pel43lRMlTPDMN0NxdpWbubkqz1", 1, "40,001", "0"],
    ["WNIBMPtoOmX7w3YyFeE7kh81A8F3", 1, "39,584", "0"],
    ["AkUEyk4WrFZ0oUSMg3SyjrG5Ym02", 1, "31,111", "17,083"],
    ["B5DAs3bSPYOU5WypTOwaPYdDfCS2", 1, "28,333", "0"],
    ["r7yR56QuJCX9EPuS2XnhV7lyTJd2", 1, "27,5", "0"],
    ["euV0nTmpB5dGrAXyy4cOqztbVuc2", 1, "27,223", "35,694"],
    ["GmeDxX0b5PMf7BkIAydUWoZhYpj2", 1, "26,111", "35,972"],
    ["U5Co9K61GpdaCszbJzyyqfrCRvi1", 1, "26,111", "0"],
    ["WBYLKITI43POfdJXw5l0EGL8voX2", 1, "25,834", "0"],
    ["3AIqVdnTkTfi0M2ou8gWFvPQrFX2", 1, "14,583", "0"],
    ["R2SgIL2Eg2ZNuyMncknl3zzRHIw1", 1, "13,333", "0"],
    ["9pXuD4HTkUXTuh4gfW21WtzmaFh2", 1, "13,333", "0"],
    ["lg8Kya0VwycseEzTVWSHYoguTAk1", 1, "13,056", "0"],
    ["lKjCwZ7KRTYeCCm8nwlwQQBDfkD3", 1, "12,778", "0"],
    ["AWOrMewUzbeNRYV8DiBV6toOnPg2", 2, "0", "0"],
    ["m85pcsqUAfSitdNwSrpgS41byFw2", 2, "0", "0"],
    ["Lhm5TZ28Fcd4VG37yLeYBh5UXl52", 1, "125,484", "146,344"],
    ["xfpMs7wYlkbdplhmwuR7J7jwRoj2", 1, "125,484", "146,344"],
    ["Vs4Y0b4XKIeoNCgyJYXx4jFVrLe2", 1, "125,484", "146,344"],
    ["qUupYXqMkgWE1Deiax0uZZrhfbx1", 1, "125,484", "146,344"],
    ["d9blFDYZvyfyIc6mEnDQftWxTJu1", 1, "125,484", "146,344"],
    ["fhTTkW4qK2R1og6zY4u6PEZBEB03", 1, "125,484", "146,344"],
    ["I6zMwWy9iJRh0Zp7MLrKH9zMScx2", 1, "125,484", "146,344"],
    ["qTJewtM1Q7ftxREcoZuqP4Fmkdq2", 1, "125,484", "146,344"],
    ["IaJOjKnyJpU5gCSCzlcD7Q0iAiP2", 1, "125,484", "146,344"],
    ["hVP2RPrsHFe27Xop9Bx3NM5Y1MZ2", 1, "125,484", "146,344"],
    ["j0pz8tOmQyXjINJh046rifcilJG3", 1, "114,731", "146,344"],
    ["DhXTIlzMILMdlir3SQTOG9R5VYM2", 1, "107,312", "146,344"],
    ["6dCWdUXuU9VJTiTKXy61nuLGtE02", 1, "107,312", "146,344"],
    ["u3mt9eWobbhCTs3dAg98coXpkfH3", 1, "107,312", "146,344"],
    ["QmzPY91WgJVKmreBd7TVZkkKS1k1", 1, "107,312", "146,344"],
    ["WS5sXkgQChOG3DC9HaQHQTuDubO2", 1, "107,312", "146,344"],
    ["6zQrlJ35OVcXe5ZmgsZIPAk2ELC2", 1, "107,312", "146,344"],
    ["RoxmQZu58MOj34kOPmbg1lleYhh1", 1, "107,312", "146,344"],
    ["hBZUA519XuZ6lzhRUBBvsKxujZX2", 1, "107,312", "146,344"],
    ["UjZdBkJyMGe9jQcdMQ25fsNhlfF3", 1, "107,312", "114,624"],
    ["l13sbsdOK9PxxnFUF4rYa1rer2E2", 1, "107,312", "114,624"],
    ["Fd4m3IF0MNYTVveopeBlQkei0Iv1", 1, "107,312", "113,548"],
    ["I6JBLNqP7aY2NObRYEkBi2CjU9G2", 1, "107,312", "67,849"],
    ["YLMztUDOLRVZ5Zymc5C4zV2Af4N2", 1, "107,312", "66,667"],
    ["lLBCY5jfmWh7OMbMda7jzeiGbud2", 1, "107,312", "66,667"],
    ["wzqHQAbNV0OEt09vTm0NI7ijPUh2", 1, "107,312", "38,71"],
    ["MLrewomoEYfzoxjTOpkOsniAuRf1", 1, "107,312", "38,71"],
    ["JC4gmcnLezYGxFhKAm93cR84voF3", 1, "107,312", "26,452"],
    ["fVHApibHybbBeyoH1NaE0J744Ty1", 1, "107,312", "0"],
    ["oRA9p5BOVtXGAIBha55DaXAT9I52", 1, "100,537", "146,344"],
    ["nZpFCzPW0vX31Dy0Fz2ZjoVHD2x1", 1, "99,248", "146,344"],
    ["JasxRGotErMonHBIrWrvkZM32su1", 1, "99,139", "0"],
    ["7NRXnqf7bmXVp9gQWDqO7xTHN9h2", 1, "97,527", "25,054"],
    ["fS0vn0H4ouUgv8lsK0oel16HEzG3", 1, "97,527", "25,054"],
    ["QVbnMtXCunRtHNN8ZdFf4qXcSha2", 1, "96,237", "25,054"],
    ["vqR5spd4qzQm4hPvYU9Xl151sl92", 1, "95,914", "129,892"],
    ["LZcEFOMHRNUdVg5GxNs1MUoiLU93", 1, "95,914", "129,892"],
    ["28CkRdNmhvTZpT508XQfLrgtwFb2", 1, "95,914", "0"],
    ["iuDutUPKZSdDaS3RpAA4qM7vQLC2", 1, "95,161", "146,344"],
    ["JERv3M5QIXYnSsMRDYd8XsgmWan1", 1, "95,161", "67,849"],
    ["7hxQMmlDFuRSj63iYQeiRA1ZG1M2", 1, "93,549", "146,344"],
    ["JUKJnAWKmBVtW4C54fe4AJMYZN22", 1, "93,549", "133,548"],
    ["XLCYJwpHGAY0pFB3iiVKEwkzxE83", 1, "93,549", "129,892"],
    ["mZ4yeefqeXfDWVUk2bYHBoMY4Yy1", 1, "93,549", "113,548"],
    ["wFm72IWFZbfBCwsn3wqQHo2DZ9y1", 1, "93,549", "53,011"],
    ["blHwzNcrWcXASO2E8gfzy1lNaGr1", 1, "93,549", "0"],
    ["gjUv1Mkd5mhqblAudqOfPKe1jR73", 1, "93,118", "146,344"],
    ["XvdECJq7degm0oewAsRsa5dT8fh1", 1, "93,118", "129,892"],
    ["uILD1p3Ya4OToJX97prJOeNSyZZ2", 1, "93,118", "113,548"],
    ["MozGPlCzdVYuO5yytPcXfMlAidu1", 1, "93,118", "38,71"],
    ["4mAPydWE3Xg0mdn8FEmTvb3VILp2", 1, "93,118", "38,71"],
    ["dg2xV2S0h5UmMgsQqmKr1r0Ni532", 1, "93,118", "12,796"],
    ["fhvLqnVCukcT7ENqCG1OFGxOzAh1", 1, "93,118", "0"],
    ["QoreWs3W0ng168J7THhaET1WUZw2", 1, "93,118", "0"],
    ["Q4f60SBJsxZMlMLR5U3GVADjvex1", 1, "85,269", "0"],
    ["Lh0r1pYVaPRcKjLnCJFlo7jnnJE2", 1, "84,301", "53,011"],
    ["nMDQuVUm9deAmtOs4uOOxn0DUFs2", 1, "82,043", "66,667"],
    ["PF1w27j4kxS9eGCChgjcxwtDP6n1", 1, "81,398", "52,904"],
    ["xUkXipK4H0YzRAXFVLBxRrlcTJX2", 1, "80,967", "12,258"],
    ["oLI0T1tXAOd0rJbM9cI0rttriIR2", 1, "80,645", "82,043"],
    ["fFAoUkSF9LchC2lbatsBX72n16N2", 1, "80,645", "38,817"],
    ["xyDsr1wBgyNwHggVMEDUHW8MiCl1", 1, "80,645", "25,054"],
    ["FE7PEycm9cWm8gITKcgP4X9Tj3q2", 1, "80,645", "12,258"],
    ["Q1DtfhPAB4WfSI72V35ZAlRVNGk1", 1, "80,645", "0"],
    ["nfZT85zGQOP23K9G3T7p0wTA7rV2", 1, "79,355", "146,344"],
    ["gs3ZIXyP2ZM0Bkx3ykG2uQXQSNo2", 1, "79,355", "146,344"],
    ["URRKUxtdIHa2UAZbO03S9JzHRx72", 1, "79,355", "146,344"],
    ["MGAS9FHGhMeiMHbpEKd7VV3i3Fz1", 1, "79,355", "70,645"],
    ["pD66HO0rDpNKEnqsqgTIhc2sFgj1", 1, "79,355", "66,667"],
    ["iq0Y98tSZSfXEYqmpuBaTjsojxx2", 1, "79,355", "53,871"],
    ["XCOGxwEbf3ObEvU5xG530Lj6O7t1", 1, "74,301", "97,742"],
    ["5YjtErAXm2gIg1FinY8Chr7752G2", 1, "71,613", "40,108"],
    ["7PXVRFB7mRTDNkWEu3rs0W7gEfp1", 1, "68,494", "0"],
    ["tY2kMnLGwQcBda7oBgReSsPC84P2", 1, "68,28", "12,258"],
    ["JVYYIsSIyhR7poJPE2cqfLnVmIz1", 1, "67,204", "146,344"],
    ["nRIlBJLi5OPuUbluvTKJ4Iv2wRD2", 1, "66,882", "12,796"],
    ["dd6qjq96kwhiruiyVJGiBoIG2Zh2", 1, "66,882", "0"],
    ["eCsvX1esKWaenvJlA0UyMtlliTJ2", 1, "62,366", "0"],
    ["wLBVcGHZUIfXrIxvlTNbOnCvQZ02", 1, "60", "146,344"],
    ["vCZe94CI7dTWxw2uDWvL41VkYpt1", 1, "59,033", "0"],
    ["mR48voietadubCGMtZBZGDL0Cui2", 1, "58,818", "54,409"],
    ["DRUYWzO5pbNeKXIQlBpEpM7ypzH3", 1, "58,818", "0"],
    ["Rrjh5wT52RfBLGMHdGvDBOIux1A3", 1, "57,527", "52,473"],
    ["dAFz9c9WeiZHOqkiuC2kNp41nPT2", 1, "57,419", "67,849"],
    ["g8qqByiHgPUMXji7zXnBNCBVujX2", 1, "56,344", "25,054"],
    ["SGsl7GqTukfnZVBNUXAJxsVsnxK2", 1, "54,731", "0"],
    ["8TnMho0vkwNrOGTenZFSmJq8eFJ2", 1, "47,743", "53,871"],
    ["FL1lVuWvK5WREygHQOFEkWCFOnl1", 1, "46,559", "12,796"],
    ["CuZMaBRNoWh49Z7jVpC5uW23btm1", 1, "43,871", "26,021"],
    ["i3OaQ7sWbdhoI5vWALnONulfbsy2", 1, "36,129", "0"],
    ["DzlFpCr5QZRpVdgkqvfkbacNunw2", 1, "32,796", "0"],
    ["ljqZfwYfJ8b8mdTUSXmbBaFD6Lo1", 1, "32,473", "0"],
    ["1cIdhhEZCmQ6wjzaUHw6EHuPxXq2", 1, "21,505", "66,667"],
    ["1eDksNsabWf9eSam8u5WYWgawzv2", 1, "143,402", "57,937"],
    ["Hcx1qAKLLAglJ5eGnQPIxwtxMnp1", 1, "128,557", "0"],
    ["F5sDk7EaP9VqfYWqKFV4e2KOPey1", 1, "125,67", "0"],
    ["U3Ot9PlOtMRIjkv3gO0343Z8E3x1", 1, "124,021", "149,484"],
    ["LmmcSJp1Q6dYOT4lDeYxDCFDzsF2", 1, "124,021", "72,681"],
    ["E64Uh4LDQKSCNZ0E0zohzBZNdED2", 1, "124,021", "54,33"],
    ["VIJuohk3m5gpvcAz9UqluVlVx6U2", 1, "124,021", "36,392"],
    ["jAjq13Oa2DOzfVVuEmcFIIbLtRR2", 1, "124,021", "0"],
    ["wZ7YS4mqbtRN3F62bXTMq8wlJjj2", 1, "124,021", "0"],
    ["93r2O8J1fCWeBaAPJOPBSO0y3Bx2", 1, "114,948", "187,834"],
    ["OfwzKQruZPSJjesDdWP2u3NjREL2", 1, "114,948", "187,834"],
    ["vWEvDKlvECTJ7ZCTKWFjnXfesiD3", 1, "113,093", "0"],
    ["RHwPo7dIticGmmZtguIHJk4aABa2", 1, "110,413", "91,547"],
    ["qaeVBCdM6PVyxrDxPfhTEIW6pFj1", 1, "110,413", "91,547"],
    ["nsesCTKPzzMyhuFBIP1gyIPsxp32", 1, "110,413", "17,732"],
    ["fUe7rU3sE1ZxvpYiVlsl5XrdFB92", 1, "109,176", "130,618"],
    ["bmxidUDqFZRstl75K4xVHI0XJCt1", 1, "109,176", "130,618"],
    ["XK4AKgrUgAff7Jd58bEsnhwGthL2", 1, "109,176", "111,855"],
    ["pMj5src7xBMQ2qD1ND9WvgtnOpD3", 1, "106,289", "54,949"],
    ["v8mSPdNBXbMhbnqXDnMlBeMyc0l1", 1, "106,289", "54,639"],
    ["gARfojx22IV7MgFLvX1RXacXiIw1", 1, "106,289", "18,557"],
    ["hspnf7wrjdOsNlDi7JcOfMBsY082", 1, "106,289", "0"],
    ["wOw7RKGUOHTA5YyPOQtHNvubWht1", 1, "106,289", "0"],
    ["ykkSIIZkLSNR3vWe5Xs0wLItr0m1", 1, "106,289", "0"],
    ["ujMTNrnjAET3PqloysgtQVHwjJy1", 1, "106,289", "0"],
    ["p9ETH8Uv8oQvY7h6wIBCTJRDF1p2", 1, "99,072", "0"],
    ["46ZHorXJpzN25NEZ7E1HVhPhThN2", 1, "97,113", "0"],
    ["lhOZAZDSESeYA3VvcZeHiL3BE3P2", 1, "96,186", "0"],
    ["vZ3Xr5Ee0LgSZRrC4BAhruW1EL03", 1, "94,949", "0"],
    ["He4nT9F7h4Vv4fYSoy5s9fIwcHp1", 1, "93,299", "0"],
    ["o2lnlhJ0SxWk9Q6ZvFwyo9FuRLK2", 1, "92,681", "17,732"],
    ["nIkIoIW3kkWsPri40FRQDj4ixCR2", 1, "92,681", "0"],
    ["WmRkHrtxeYZnrJpfZ4dh3tvoBO22", 1, "92,681", "0"],
    ["Bj23pCyWO7XJDHD052cmbwyLNxG3", 1, "92,681", "0"],
    ["dkZ4zAVyOHNFDSNPn6QOjgTmMk22", 1, "91,444", "0"],
    ["k2g8oVKI1YPjiqIiZBU8U4Q6ngp1", 1, "91,444", "0"],
    ["vEZAFNhRfQO84jI7NLucFquFYP62", 1, "90,825", "187,834"],
    ["wojP5o2ZJvcUW4uwQMqrTT3Q4BE3", 1, "90,825", "149,175"],
    ["PeMjCORh3ONetO6M1JIbfs7sZJw2", 1, "90,825", "0"],
    ["VkhuSW4voCfAKg1GsN3aqpJK1Xw2", 1, "90,825", "0"],
    ["rwHf3O2gOJgGgNf4LvwHcPFa7B93", 1, "90,825", "0"],
    ["ftKVsRbT8ZfY3fvr02fasRGlVgh1", 1, "90,825", "0"],
    ["9SRA3SFIjxP3wpoGPiwnjQeEti82", 1, "81,443", "17,732"],
    ["MsbUY6lTzHUSVTcorSiNztpDpjq2", 1, "80,722", "17,732"],
    ["Ir40HWrLnadO4f8GdhccQXQtlMs1", 1, "79,794", "17,732"],
    ["CRDbtI0GrmNkFSRiKcQivbpyejS2", 1, "78,763", "0"],
    ["o8tJEcqF1MXMGSL6ZhDwUmTbep42", 1, "78,763", "0"],
    ["Cc40tZ3QMhcHPYlF1pTCZwtBRqA2", 1, "77,836", "0"],
    ["zNGdWXHF84UhjLjrAM9VPx1p7Mz1", 1, "76,907", "0"],
    ["eeikVQXUPJfPiEymHSWVLgJF3gY2", 1, "75,98", "72,681"],
    ["fepsZLqZptP1mJcBeSj23yVEXfm1", 1, "66,598", "129,897"],
    ["iVjnIBue8TNNASjbZMztE6LUdWx1", 1, "66,598", "0"],
    ["qG0g4oPNgWehErnU4f6aSHjZoGR2", 1, "65,979", "0"],
    ["FqBCWwTsa7QH2H7TGsbrMdc4uCy1", 1, "64,227", "54,949"],
    ["sUHEWujMTFRhVLGdmsvEcS0ZBB63", 1, "63,918", "0"],
    ["VUsuNd5PM6Xibly7cz0ehvs71Iv2", 1, "63,609", "0"],
    ["snsBLNGwq2cRO9i6pjyBZwRlv442", 1, "63,609", "0"],
    ["EKQ8ekCbYidZnOuSX3NkvlwYgjJ2", 1, "63,299", "0"],
    ["OqT8dgtFzcV0Sg1RfFWSvkGPt652", 1, "62,372", "35,773"],
    ["nlBbLpSJJYhfUCIyEWcRK7KQ6hu1", 1, "62,372", "0"],
    ["vYDmIUjGgmNwWlT1AxV7E4rVHjv2", 1, "62,372", "0"],
    ["pxTs7k6rjrVthkMDd1obJ15TFx22", 1, "62,062", "0"],
    ["upZN2GCNjTR17fbnDelSFNxZcNU2", 1, "62,062", "0"],
    ["k6nYyMYZQURBW85Wtupw859CJ3K3", 1, "54,845", "0"],
    ["61KHgH206xVyC3azGDvZet9hdAm2", 1, "51,444", "0"],
    ["mC2LypdMLzW1gEJUklKL0WYjLAH2", 1, "51,238", "0"],
    ["jyAiKopceWeOhCUbfRQihFeXXOy1", 1, "50,928", "0"],
    ["YuJjLSL03Fa3En57Jmm6FPbz3Ru1", 1, "50,31", "0"],
    ["rAO9ts4yovZmcUqD3UvbdcfPR413", 1, "50,309", "0"],
    ["6aprTysWnlVyidgWOoXiPXBwj6E2", 1, "49,691", "17,732"],
    ["WHNv3r3Ngsh9OX3OMPLVbtSXlaZ2", 1, "49,691", "0"],
    ["GeUhTxUjlMdizBOfeT320wVZ3qt2", 1, "49,382", "0"],
    ["RZKOvrc4TtcuDOeE6lIkZ75UArM2", 1, "49,382", "0"],
    ["aMP1uhE8KRd8cqn0gqnYkFJLvrn1", 1, "49,382", "0"],
    ["P3Vvdc7B9YgdFcGGEdHGQASbmCu2", 1, "49,382", "0"],
    ["Lg5u2Bvm7nTDnZZaFaExFUSQQoW2", 1, "49,382", "0"],
    ["cX2A0XPWAea7CHQC2Ptuw91IiLd2", 1, "48,764", "0"],
    ["nhhma5MyhBUm6Px9w5Xo9Az7j9R2", 1, "48,454", "0"],
    ["fp7JFsM1L1f6JDynZZQqFTrISfg2", 1, "48,454", "0"],
    ["x8PYPlGbJiQiYpmWA548Tb6VvJl1", 1, "39,381", "0"],
    ["w6SXsMUKBFQD8RzXoGN6Lmuzcek1", 1, "37,526", "0"],
    ["7m16f2arFOM3qJDOf1dnrF1pwaD3", 1, "37,526", "0"],
    ["L9dXOxn5h6XqpXt5KSiwt08FnaI2", 1, "37,526", "0"],
    ["Xbr0TJScdGYbu3nVhxNDfoFvcyQ2", 1, "35,464", "0"],
    ["EmDwpKyrfubWnqeXaydK0c1sDVp1", 1, "35,464", "0"],
    ["k5xXAANdxWZtpcvCMp5iX25pKpA3", 1, "35,464", "0"],
    ["dqukSsiHUEa3SHsZazI0sei4YNf1", 1, "35,464", "0"],
    ["Jdt3i40ueFeL7OIEtOXD3mVgEVR2", 1, "34,846", "19,072"],
    ["PTRPCIkZQaf7N3j0TPyT8B8u19e2", 1, "34,846", "0"],
    ["RVKIsShHpGV1YERAxVs9v54GWuV2", 1, "25,773", "0"],
    ["t7PAbk3ervagD1oIfc6SEVZF7QL2", 1, "24,846", "0"],
    ["KKt3ehVr8vRS2VY4EMGSFCOopm53", 1, "24,536", "0"],
    ["L2HQm3q5hxZUb0QOq6tnJn7FraW2", 1, "23,918", "0"],
    ["AwU00OhKaHRXWwtvgagCFyQinSp1", 1, "21,856", "0"],
    ["wSwh567ctmbC1adQj8qpyoLte4w2", 1, "21,856", "0"],
    ["2ifaGjdawsSt2NsAhNqrKsP7A9H2", 1, "10,928", "0"],
    ["TbQMZ4t0OSdqG3qdyvdvddFWsp73", 2, "0", "0"],
    ["ufS6a7QcKQVyYlneFTgILk3hH483", 1, "139,085", "174,273"],
    ["Asa1zy2FjIffYX3l4gtXkhEzk8i2", 1, "139,085", "174,273"],
    ["43nsrpx2JQhRyuLAKq9lJArv01G3", 1, "139,085", "174,273"],
    ["SlH68P1bZNfujfJQer5wS0VWeWo2", 1, "139,085", "174,273"],
    ["08ynAWi89sfHeDINFcTa3byWFI73", 1, "139,085", "174,273"],
    ["aFflj09KvrQ4OsN4ELR495N6gun2", 1, "139,085", "174,273"],
    ["wv0BFdBX8hTOW6TvN1EYDU8vWhH3", 1, "139,085", "174,273"],
    ["UeajC59QEzZItLzfBTxBXiu6AkI3", 1, "139,085", "174,273"],
    ["XHRJIomhJNet6zfRA7pIqj5jCqH3", 1, "139,085", "174,273"],
    ["vciDMFjj76blmGHN1QvkbtktzqO2", 1, "139,085", "174,273"],
    ["SOSXHzdgrdU1fTavWwdlepMBBWi1", 1, "139,085", "155,595"],
    ["DlL8siUTtthelX9ow7HbM5nG1DQ2", 1, "139,085", "155,595"],
    ["EU7OgONbXzeYJmo2ba0UjaaVn1J2", 1, "139,085", "137,154"],
    ["zYFWPx9tFlZLugKLtUsrJHIXgxi1", 1, "139,085", "119,323"],
    ["GD7wZQmAJGPHlnZCgvMJTGv0fqx2", 1, "139,085", "31,729"],
    ["4GWpKDo2OtaeufjrhRbDMkoDvio1", 1, "126,848", "174,273"],
    ["Y5VCCaeTahYnerdq4ETPyjfkrag1", 1, "126,848", "16,949"],
    ["47NMpuXp8TeFsMZvjxHe3A5GO9x1", 1, "125,627", "48,678"],
    ["THOxN5vq2QUmVh1pbOXZV1CX6RZ2", 1, "124,712", "137,154"],
    ["vyW9GOcdcoMndNSJEg4Gok7D3U02", 1, "124,712", "0"],
    ["Uex8zZkTolgosqebnj4EeFvbcFk1", 1, "124,712", "0"],
    ["gr1D1BjrbCV2p3S71qakqPNSEyj1", 1, "124,712", "0"],
    ["TAGT6CWBmjgPJCNGM7xATa6oR4N2", 1, "124,136", "16,102"],
    ["uwr9LTKa5fU2OuSJcWppcdHrBOt1", 1, "124,136", "0"],
    ["HofSW8TYKzcj6mWX2O6zt8OFM962", 1, "124", "17,017"],
    ["u9Fl3g9EttZORR1l3w9ilg1zuIH3", 1, "120,543", "174,273"],
    ["Z60Sk4nJdkfSTz9UQcXbVUVqcVD2", 1, "120,543", "174,273"],
    ["F6H3vP2iiqTCC4BGaBXg4C51BsQ2", 1, "120,543", "174,273"],
    ["7hCbWJcWiRXT5Lz0Ed7CaNsYOZL2", 1, "120,543", "174,273"],
    ["yjrMYRvqDlalYqeCiymqXr8YPUq2", 1, "120,543", "174,273"],
    ["DoFc2nqB42gXmmgVCAyKdJ1jE542", 1, "120,543", "174,273"],
    ["HvWNreLqddNvMDq6NzWPNXLF0zA2", 1, "120,543", "174,273"],
    ["VeQcmnYb07b0qjzjuEyglXJSL6P2", 1, "120,543", "174,273"],
    ["Ii68u2YwMSNP0cCzmwp50ogJaYt2", 1, "120,543", "174,273"],
    ["RAOIYVE1HZbS6xzZOGuY6T5tErF2", 1, "120,543", "174,273"],
    ["dMrdCHWUwaZZqqPfzXptJeJMLSn1", 1, "120,543", "174,273"],
    ["dTk6CD3ukOXBWtXHW5uGM1TUm682", 1, "120,543", "155,798"],
    ["o5RvR4fBOZUwktLrX7DekBqQ2AV2", 1, "120,543", "155,595"],
    ["OrCVy3WfvcPnCPzsTQyQaNGqyle2", 1, "120,543", "138,239"],
    ["rdINLkSOX7XWUktaznQrGA7oyoh2", 1, "120,543", "137,12"],
    ["xaVoq9X8qGPJEFTcmrvwDX8fgvs1", 1, "120,543", "137,12"],
    ["G8yrJx4Vf4M6BbVw15FQBQoZNW12", 1, "120,543", "119,967"],
    ["g5bbxvJaKPYvyCAdyXNneo77I9M2", 1, "120,543", "101,662"],
    ["O2vCQcBTomXNDuiSjkH72iGPLFI2", 1, "120,543", "100,882"],
    ["Dc0xWkgzYpNI3p357cVHTdPNKm33", 1, "120,543", "83,933"],
    ["mEU3M3IOnLQ12U5DR5WrFCJLAdB2", 1, "120,543", "83,051"],
    ["2XCzBJrvsjXykFfp1oyM4mbdwny2", 1, "120,543", "83,051"],
    ["nF9n62AA3KXHLuRO8pNeTVmSvlg2", 1, "120,543", "65,695"],
    ["zJQF8aq28pMr2HZ1DKiumsFN4zq2", 1, "120,543", "48,746"],
    ["xj5iV8tJZBYlYRAn3w6ETxuvrX72", 1, "120,543", "48,746"],
    ["n6GW93bBEtYJc0dIlML16qFAvF43", 1, "120,543", "48,746"],
    ["zI57U8Fn4EewxrDz9vT2eABJXSm2", 1, "120,543", "15,627"],
    ["rrPNzM7gwaYny0aap3uDPVpTej72", 1, "120,543", "15,627"],
    ["rNUehD0i0VYns2j32uBF7hzeXBx1", 1, "120,543", "0"],
    ["Fh02cqNLjWd0iM3J4lE6lI9m5mF2", 1, "120,543", "0"],
    ["0WJt6Csy8GVXaux2qILPU56e0CE2", 1, "120,543", "0"],
    ["0qS19ClwmhQJc5HBcX2LfvgCxBX2", 1, "120,543", "0"],
    ["84idm5HOPmbh82dwuTeaQLNW9Cs1", 1, "120,543", "0"],
    ["6XHuOSpBscfeqfRFqf6XqWRIPjs1", 1, "111,627", "118,679"],
    ["kqSCtIPzi4XkxZ8GyS5Bo1MnohU2", 1, "111,254", "0"],
    ["An2QGZfHgLcemP7oKVsQJqf5qEB2", 1, "110,814", "66,034"],
    ["hXZqV6djIRcuzbsXa61dGY6rrID2", 1, "110", "0"],
    ["LwzBRzIxtFU07Kayfn7XKhapD9P2", 1, "109,864", "15,627"],
    ["HgPhVtfx0IRifJ6KRyyKJkz2TIi1", 1, "109,187", "155,595"],
    ["fbTBmHYhJKZh0cnSJDlK2i8Tjs22", 1, "109,051", "0"],
    ["xfZhMiixGyW35uFwt8zjB8Tj2Lw1", 1, "108,306", "137,12"],
    ["EfxLgcB39xatqosALwFXzdOfiEh2", 1, "108,306", "0"],
    ["olSoLVDCasYxbYdhm4V3nFXhWUf1", 1, "107,221", "101,323"],
    ["DUaQVwPZAnhWTDzHTOEj6SDmxFA2", 1, "107,221", "49,085"],
    ["pmoENhdtqEXBKz3LFzF8xAuNYov1", 1, "107,085", "0"],
    ["CVoPaXUvWaYxdx9ri7SWj5fdv3A3", 1, "107,085", "0"],
    ["VlOzipXLs8ctDxJfasVDiNWe9uH2", 1, "107,085", "0"],
    ["VYwXvdOit2QXe5cx9lVIG8ZEbQz2", 1, "106,407", "15,627"],
    ["zrlUXBCjGfduWzDoSfjwstJhlIu1", 1, "106,407", "15,627"],
    ["U97btey75nSVKGk9rpXCVYYsUb32", 1, "106,407", "15,627"],
    ["FrsWJnMlGXYMJQuqtjOX33hEoxd2", 1, "106,407", "0"],
    ["O49fGxJwqXSaoW0FBmN9gk4Q0av1", 1, "106,407", "0"],
    ["7tegx6UFlvNDcjKr2mct32ZPwsR2", 1, "106,17", "0"],
    ["pnJTLFppq5Ulp6ujjui3oMNtTok1", 1, "106,17", "0"],
    ["cepML1lxomSEUnqghTsTWeEUmD12", 1, "105,594", "155,595"],
    ["174Lp3YANyfOopVRRHO3WQRv6mx2", 1, "105,594", "120,17"],
    ["kDCJ9GMIbmh6miKxphYAv2TVlJv2", 1, "105,594", "86,035"],
    ["eHKhbsreDiW1wiaCsEIUZVUrhTh1", 1, "105,594", "83,051"],
    ["yktIoxSZYgOq7hKNd20lUxdbW9q2", 1, "105,594", "16,102"],
    ["P1u3HIB1etesgFR0pdP1n0hLR963", 1, "105,594", "0"],
    ["Wy8w5C6apwRFWSOwiwiXZ2U2Eay2", 1, "105,458", "137,357"],
    ["UbMqPFIl7cRFhv6QGNb5UgZMYdp2", 1, "105,458", "83,051"],
    ["TfO6EN0dz8gNwck1XPcLe9X7B2X2", 1, "105,458", "65,695"],
    ["o8KHOpn3MLbqhlMEwAOjPxCLZkE2", 1, "105,458", "16,102"],
    ["W2r7FnjmBdRW93GKpUqQOjMufho2", 1, "105,458", "0"],
    ["jZL7oeAgTTSb7ZkxRuZKnhvsish1", 1, "105,458", "0"],
    ["9MbRWsN80RaraaC3xJf0nRdcEdn1", 1, "99,153", "48,678"],
    ["IIM0zq6SQxclNusqEdGzVOWhN7o2", 1, "99,153", "48,678"],
    ["vM67HoGzaRewXM2NJn9VTyeONSF2", 1, "99,153", "48,678"],
    ["KxipGxaUQ1gwiBFCkJqV679aPge2", 1, "96,169", "15,627"],
    ["IpUF0tyU8kRX538OxoTGZCjTcCG2", 1, "95,865", "137,154"],
    ["lZEAmYo7XkQODiF5Q5QteX3vUgy1", 1, "95,729", "32,983"],
    ["nfWHnQNg06WKbqDSm7YfWJ63hFq1", 1, "95,627", "0"],
    ["FXBYjdHuDUZcepRjRMvPmQfHwhO2", 1, "95,594", "174,273"],
    ["t0AV4MXvz7VGbCXoVeIRdvF166J3", 1, "95,594", "31,729"],
    ["2BbomuC6Cbho6EAZw18KWRlKclk1", 2, "95,491", "0"],
    ["pbEx8iVezPauJjXJrwiApjlriJ52", 2, "94,17", "83,865"],
    ["fsMlxtQylgYzsZiQ0PcIR0znyQq2", 2, "94,17", "66,102"],
    ["mRVyFhmVzYP8NTxHmovFbMhMxMB2", 2, "93,763", "0"],
    ["FL6FMLPQMCVfZV1BAj87VGsNcgH2", 2, "93,357", "32,576"],
    ["XbIE0P8onacFhy0MLGRp9Q3fFfe2", 2, "93,357", "0"],
    ["vpV0KQnNLRhghh9bomj5Oel6Y5a2", 2, "93,357", "0"],
    ["NECMUh2USjS4agE2ZhI3e90jvli2", 2, "93,085", "0"],
    ["h5OpFt7SCOeqaPo94zDLbqEuWqi2", 2, "92,848", "0"],
    ["7NpSliYmbXQGgz0Vyzr3zeRUIed2", 2, "92,272", "31,729"],
    ["QKWt0ultVjMZvvGZFeWlKOaFxqu2", 2, "92,272", "15,627"],
    ["DAa0jP7PJMT6hxQEpbINhHn8Sfc2", 2, "92,136", "137,154"],
    ["3woFUvIpPzbthv0OsWWOAVQZsJC2", 2, "92,136", "0"],
    ["tjWqT64CNvhCZhqp8CQAkpnK4k02", 2, "92,136", "0"],
    ["31X7en3kD9QUzR2yKBehJ2t1WDi1", 2, "92,034", "48,746"],
    ["m7PpCMCg8jUx72dcPRXLRZ6R6ip1", 2, "92,034", "0"],
    ["NHfFuNZN9AZpxUWkEi9slCn4u472", 2, "92,034", "0"],
    ["ku1TCmNCGpYOwn48vCFS4gktxOc2", 2, "92", "174,273"],
    ["RCq2MeKvTIYGQ03VDLAfbXDiQRc2", 2, "92", "155,832"],
    ["WlVxuDINphct5Glxuzzdcu5SX8Q2", 2, "92", "0"],
    ["hq2tW8Xh2wZvhrrwyEzu3Be9Vfs1", 2, "91,458", "123,459"],
    ["VJPr5CSwhQVEGTHhIL4Y4JejqNI2", 2, "91,458", "15,627"],
    ["nTkYzxdAkmTojG4dZW46RfPf7972", 2, "91,322", "174,273"],
    ["mhulazjC7lTOblFkqNm37BIuo812", 2, "91,322", "48,746"],
    ["eVwTYpWdtThZFJkLPgcVnbuSk7Z2", 2, "91,221", "155,832"],
    ["5SylP0uaOWUCGWAHaYg5gRU62QH3", 2, "91,221", "0"],
    ["vKgNyl9sPvUyysIv2s3OC1HKTIE2", 2, "91,085", "0"],
    ["mIHjUXgiH0bfJBnsUP2j99vINI42", 2, "91,085", "0"],
    ["l7Jwx57a6ghVP8uGgNZrSMaK0Vu2", 2, "90,509", "155,595"],
    ["1bcKUcF3xLSlcVlEA8Xq23cMGY43", 2, "90,509", "137,967"],
    ["irZMWoYh3ygIxMS9pFOFb8fFMGh2", 2, "90,509", "83,051"],
    ["xgIqixDsndOqvJiShWWD9mg2Nrc2", 2, "90,509", "15,627"],
    ["N9iudBBERPey2qIvaVbQkKu8sbw1", 2, "90,509", "0"],
    ["qwAEPqoBGCSgBw4m8Pf1IBNw4n83", 2, "90,509", "0"],
    ["PDbDm4Q3qfcDhwYrjqYVvve61ei1", 2, "90,509", "0"],
    ["qGf2oEAIRpM3VHQlSxOazfNxJJL2", 2, "84,102", "0"],
    ["OeQzOwGzKmh7j3ogdxbLExowmn93", 2, "82,678", "118,679"],
    ["sDLrNOe8D2NjawlcxmINOSiIRkV2", 2, "82,678", "0"],
    ["5BTtdA2x6Ld1JVRvCu7b9aVMGr12", 2, "82,305", "0"],
    ["x5xvRLBsohNMSakWOihclwDilux2", 2, "80,645", "31,729"],
    ["hWztyaNRNmO6oYELbmgZI6er65l1", 2, "80,102", "118,679"],
    ["UAGUedZLVRMvanQr2UcTDqzoNHl2", 2, "80,102", "118,679"],
    ["y8cdqn60nkZBPY1i5mPJwLyfRDu2", 2, "80,102", "118,679"],
    ["26R55SugsnZ3akHTmow5zomMMzF3", 2, "80,102", "118,679"],
    ["kfaBwIEc1rQr8lDSiVJp6fgOqBi1", 2, "79,831", "48,746"],
    ["3dESb8xw72YAcznVr2SMOs6xpkn1", 2, "79,831", "48,746"],
    ["pFE5kR5FHDTEWMjGKv6zbyJG6mD2", 2, "79,797", "15,627"],
    ["jm1r1h5CjDckfkkPSu2rYnkOov42", 2, "79,085", "67,357"],
    ["ODb4Javtq1SOmbEzUCZRkZ28yXf1", 2, "79,085", "0"],
    ["OhE0l4EeiNQFWvEGXS58eL1GEZ22", 2, "78,882", "50,475"],
    ["PwWLfX9heXUVnMfMQ5FOMCfh9563", 2, "78,848", "0"],
    ["Cue5n0LI8pRReufbmlTkcCqF7EA3", 2, "78,848", "0"],
    ["Mzzn0W7UqCcbTMCKJz4jFfOM6DI3", 2, "78,814", "18,678"],
    ["BMmQcBSAqbUXdmeHIHIAc4gWaI43", 2, "78,814", "0"],
    ["Rq0X99TxBOd7tvxCWRTVGYDKV6I2", 2, "78,678", "15,627"],
    ["AIRR7vDqEQPD8WKItcdzYdScQtq2", 2, "78,678", "0"],
    ["MH1FNQU6wkb87ZAcZTevnJDn9yN2", 2, "78,576", "121,018"],
    ["nrlWMSps32Oyxp3ty9uNcLL3t1P2", 2, "78,576", "118,679"],
    ["yquYBnr60ZUotCN8cYKqCOw2oVX2", 2, "78,576", "0"],
    ["aK4iZO54mjY0I3LAD5Ptdg9ofmE2", 2, "78,136", "174,273"],
    ["mciX6gqPbheSPApbVzjhWuSK98C3", 2, "78", "84,78"],
    ["dKMBP0Oh1mNBZ46zaMeZNQbZSS13", 2, "77,899", "0"],
    ["iKxDrtK3MPUPBWNjrffqml3zCVa2", 2, "77,864", "174,273"],
    ["RZOOEImRs2PEoHELxk0mwO42rVu1", 2, "77,864", "48,678"],
    ["bmiInwrJi4eucVhEBmMUxtSp8Mj1", 2, "77,864", "33,899"],
    ["DffvAzm9V2UhBrawRWQi23ub2U02", 2, "77,864", "0"],
    ["twqaqo6f8YS0vl9pQ2SHc1GU4rL2", 2, "77,763", "15,627"],
    ["kwC0HnmnfWNr8Guwgsv7UJSviaF3", 2, "77,763", "0"],
    ["EJfRU9MpNic7pkkEfLeb8mHE1I73", 2, "77,763", "0"],
    ["EQC6cQvgcoeHmhY577WchaX8BXi2", 2, "77,627", "0"],
    ["JGbeBt2JuuW2iP3F0rT0cH2r1aG3", 2, "77,187", "48,746"],
    ["j8YLz0h5zOOyWtXUzKWfnNNRNYL2", 2, "77,187", "0"],
    ["SZHCz3xUyQh2G1dMX9jYPcEGaFb2", 2, "77,187", "0"],
    ["kjI0WIUIzJSZsJOvqBEhvmCn3em1", 2, "77,187", "0"],
    ["pgwD3GcMzETZDzcic4YKmCmio3g2", 2, "77,085", "31,729"],
    ["MogVG8ApZjgH9vhDq6JwaS7BOxD2", 2, "77,085", "15,627"],
    ["pCQJsAO12kWmBJDSz7i66rXXKL42", 2, "77,051", "0"],
    ["PS68z8ebHNe4mJ3JxuMyGOZCSXF3", 2, "76,949", "31,729"],
    ["O48JWsIZY0fR45t8VzMBwwP7Som1", 2, "76,949", "15,627"],
    ["gOim4S2oBwhS1GWCMGEzT5FxPDD3", 2, "76,949", "0"],
    ["K8nSAUZJPrUKiHp9ZdpNBfKOSQV2", 2, "76,949", "0"],
    ["slUhPdXVGPY7Sa0gufsUvd8ItRT2", 2, "76,949", "0"],
    ["lyh6hU01IyRSxzW4veNafw0UNbr1", 2, "76,949", "0"],
    ["XCGPKy3o4wOVDg9f7yD0awnyA693", 2, "76,949", "0"],
    ["xfBh26nVFKaz5VUgV0mIhZQ7lzE3", 2, "76,373", "33,119"],
    ["IfNO8IzpqPfwwqkdIBAuXUvUPzD2", 2, "76,136", "31,729"],
    ["mZYQPP3By0WtAauyHDT1hxT393a2", 2, "76,136", "0"],
    ["4q6FTq0fRQSuHa0Bb4RRUAGvl3y1", 2, "76,136", "0"],
    ["pqpotAYWlJPPwRN71w0qXXxhtWf2", 2, "70,95", "0"],
    ["khqGwNihhwN8I7BGwczEACL53qw2", 2, "67,187", "48,678"],
    ["8DseN5qxbhXZg1aLSnVu00NFrmw1", 2, "66,441", "0"],
    ["zMUrUgaqDvRLJVHzGsBTAipRRLz2", 2, "66,136", "83,865"],
    ["9BQmTLDz7QTCIPSeS8bMDvY18hH2", 2, "66,136", "0"],
    ["INe9FAtoiWVT1PsLcr6Y2lPOMD33", 2, "65,662", "16,949"],
    ["K7j9ZXIv5HRqnRKDEyEGdSK5b3K3", 2, "65,526", "100,882"],
    ["7yCzmyOdmuhCosOZCtX7PGCDoRg1", 2, "65,424", "0"],
    ["uWy16atsnBW6lQ0I3u88aHmvYXN2", 2, "65,424", "0"],
    ["PC2g0kQzSjRrLlirMjRoBFLdRwZ2", 2, "65,39", "0"],
    ["PrpLKD6Gq1T0hjZi2CzuDW2O6Ub2", 2, "64,848", "15,627"],
    ["7XRzHnJfT1MnmbPyRGewGZyQZOD3", 2, "64,78", "0"],
    ["bJ0dACubAcVniv0gdrvAmNIDT033", 2, "64,746", "0"],
    ["KMqrjv7m3vOkhMyJddyfkuHXNJw2", 2, "64,678", "0"],
    ["UPNJtC44z3f9vVFmn06j8NihJn62", 2, "64,542", "0"],
    ["kRkU633HTYM2DUgFfrDOBudUL2h2", 2, "64,542", "0"],
    ["RufIb3FDP0aXgI5tzJwySmdfeNE3", 2, "64,441", "174,273"],
    ["WLTToMhHm8UojbCNiGIlboH91ma2", 2, "64,305", "65,695"],
    ["4scvnq0SOSbLzCUNVmELP2YeVli1", 2, "63,627", "0"],
    ["KetDiQ9mJybqQdbFEwZ5g73XQpl2", 2, "63,627", "0"],
    ["Uq7TzYwKxTXH3eJBpcV81p6meIY2", 2, "63,627", "0"],
    ["xse0kzb1LHRU8vu8ooHEBmxMhqr1", 2, "63,051", "174,273"],
    ["drq7vX3hKfb9NWScivbiNjuQvwH3", 2, "63,051", "33,119"],
    ["4kyRgQCXBWXe4sivxsU9EmEQ3ox1", 2, "63,051", "31,729"],
    ["CQSRO96xmwWxU2EAZ1Q3Lw2F8QR2", 2, "63,051", "0"],
    ["ZCdRqcorQtRqPiZKSN4nTByGvkg2", 2, "63,051", "0"],
    ["3ACBpXaGK6bkKEpZml15bMgO1S62", 2, "62,915", "0"],
    ["5r1qwYkYKVd9GO0y6Yanp90nYJa2", 2, "62,814", "66,034"],
    ["66Q6pb2IpgQUnOXBacHTRMgaR3z2", 2, "62,814", "0"],
    ["eumB0nMhhkd9ryPwXz75oSE5ega2", 2, "62,678", "48,746"],
    ["tlFCXV4iVqci0ogQ6P9biGVHPfE2", 2, "62,678", "15,627"],
    ["Y8ENsy7EYvZxJgPDehDNhmmO6bx1", 2, "62,678", "0"],
    ["zMM122HX73drf54kFRMsyTh0Rer2", 2, "62,678", "0"],
    ["F2EyIGyDcgcaPmzh3FR5IlFi8PO2", 2, "62", "16,949"],
    ["bMpFWDanTBRmeMt4tjdglcNLzlt2", 2, "62", "0"],
    ["gapOZFWiNJQnl1wrxpx6RnxtH3c2", 2, "62", "0"],
    ["XwxZVo2CB4Wu00GWyEAZduG222W2", 2, "62", "0"],
    ["M9rKejuzMlf0P0eFZSeBvj7dlJO2", 2, "55,661", "33,119"],
    ["dndNdMcJDzaDO9pQuRebvqFy6kv1", 2, "55,153", "0"],
    ["xzsQy5AmegdC35anIIFy7W5arr32", 2, "54,068", "0"],
    ["tPdf1JcpSlfgnRnpkc61Ij6kUg03", 2, "53,899", "0"],
    ["Cbv8NHETnghwjeqBW5Gkibw08fq1", 2, "53,762", "66,475"],
    ["LR6sZtOUxXcyjU8h0AWwmy7ysNC2", 2, "53,762", "0"],
    ["OE6zkGxSYEcHLODgcxnoV7slSbA3", 2, "53,085", "0"],
    ["PeXozPKgYCfAxOYlTeYYqhDAALl2", 2, "52,271", "15,627"],
    ["hXxaYwDtCrd7liXPLZYi3j7ioW72", 2, "52", "0"],
    ["u4gjEEmRZ0RcYjcKOap8Fpm0W9U2", 2, "51,492", "0"],
    ["JkkKcuuqEQVeUx6xstKpiUiHrv42", 2, "51,492", "0"],
    ["3MaAWDp4A3OmZp0QtHjHd8w1Ffo2", 2, "51,254", "0"],
    ["7CWxVl1yQDce1FD6nW7c6r8udaO2", 2, "51,254", "0"],
    ["JU78zm9lJ9PkHrc7mZG4pRmquGq2", 2, "51,051", "0"],
    ["lQp6azysJ9gjV7dVK7KBil7hn7v2", 2, "51,051", "0"],
    ["im0GT4AQUTS7bDHf2ni1NrSXVWm2", 2, "50,441", "15,627"],
    ["TZ1pNghdioMutREI3EPGTUxHtDd2", 2, "49,763", "0"],
    ["yyYR0avG3ybO2cs17lT0RGkpCKD2", 2, "49,763", "0"],
    ["OuqVFaXnPaWkt9VInEpNegsntjM2", 2, "49,593", "0"],
    ["AeusrtTk9YcU2WzHFghCx4jEM1y1", 2, "49,593", "0"],
    ["ia5ohlNxr9Sj4vAb7Li0jzGWYOz1", 2, "49,356", "0"],
    ["6rNjrnFJ0saHhDvgPFcUkSgkApm2", 2, "48,678", "0"],
    ["YHaWhKE4mEXm04A9fGRu2llfRUG3", 2, "48,678", "0"],
    ["6iFQELwkpcTivVOSbHtXrE7dYmA3", 2, "48,678", "0"],
    ["CF8RFhSka5OtQyXikVVTZJY3sBe2", 2, "48,542", "0"],
    ["eYu8RSd4fRXmCBFpqflUdPf4TOv1", 2, "48,542", "0"],
    ["yp2XQPOfZ0YRlT3xDoBNflgyZ7B2", 2, "42,271", "0"],
    ["159537nb8oSaVz1MIsQK0Q8XP3Z2", 2, "41,729", "0"],
    ["1R3kEdtRQISZRtl4aQwVdAqSVlB2", 2, "40,916", "0"],
    ["xoR0YcOVK8YJBHrE82wagYED0Fg2", 2, "39,322", "15,627"],
    ["Jrf0lJlVvKgpE1XsgqI0EVOF8Fh1", 2, "39,322", "0"],
    ["SBBPdVuaXOYT02RVdQq1DeFtOvD2", 2, "38,814", "0"],
    ["WYsO5StuceWaaP2AKRnVluOrxko1", 2, "38,813", "15,627"],
    ["CQmvMxHLXJMnUSzufGMPYlWE0RW2", 2, "38,542", "0"],
    ["yjz6RfE2ubdAxS4GwsR6UwqRPz22", 2, "38,068", "66,034"],
    ["PrZ93y2VL8aUECuyZ39NgPelbH93", 2, "37,119", "0"],
    ["qdeDQ7lw2sXIPCI7u2qoOUqYoO03", 2, "37,119", "0"],
    ["YeRtu50dnxMo7sPZPXC4Aa4CZMn1", 2, "36,441", "0"],
    ["UhEM2PbvWPONbiS3wp2H18t8h4a2", 2, "36,441", "0"],
    ["xkHegbvDPDRUAzt6sJuTkDZ0men1", 2, "36,441", "0"],
    ["3IvJGMEUgJbYreTbQ5PFyKs3WbJ3", 2, "36,305", "0"],
    ["afmRUzDfEIYdGr8wD8EtPgeRmUg2", 2, "36,305", "0"],
    ["T9ZTjY6V6DhHywDCLG2WMuJWlZL2", 2, "35,22", "0"],
    ["j7f6kzjbCtf1PBwcwQosLYv6kuj1", 2, "35,22", "0"],
    ["GGalUhgcc0QfgDhCESuN8hFnLiv2", 2, "28,407", "17,797"],
    ["KvFWaQMJwWclZCaJ9iRjT68KRV13", 2, "28,271", "0"],
    ["4YcmA7bDxFh7YqykJEmqDJ7DiDH2", 2, "27,322", "0"],
    ["23FsAH4p5MNfteoBwmi70trCTlm1", 2, "26,61", "0"],
    ["TKHyq2ihWxeEwYvrCKVlcaCTAKm1", 2, "26,373", "0"],
    ["DCzUpLDoVTV92d6FZzQUbgow3982", 2, "25,559", "37,119"],
    ["4hPS39CjwwXRl391IEmRH6iB76m1", 2, "24,814", "48,678"],
    ["V4rulFVRJiQXvxzcBRjknletYr93", 2, "23,864", "0"],
    ["zusoftL5moaULtTkqalZJBpsnPG3", 2, "23,593", "0"],
    ["IiGqfs7LrJO2OJKoMCiIXAoB18R2", 2, "22,983", "0"],
    ["RvU1aqC8cwY0ZLOYmIt3I0teMsw2", 2, "14,136", "0"],
    ["p4l9mqPRLQQNiIb9XXDRgEdTUha2", 2, "14,136", "0"],
    ["lNLCp22yIwXqN76AhdQLjh60cdH3", 2, "12,237", "0"],
    ["U10MsN2YxoSnbJee6y0KqVJLfHd2", 2, "11,627", "0"],
    ["ZyzW5d2ETQXK9PNY7tBv2ODLwc23", 2, "11,356", "0"],
    ["9fCIjSO3vobuSsQnAVos1YJr3WR2", 2, "11,356", "0"],
    ["C7ZDyx4K3yeZ9kKKftXOzLDwsxB3", 2, "11,356", "0"],
    ["fMrEk22uQpha6T6kSkUeBpGp6kB3", 2, "0", "0"],
    ["PD8352WZjPejxyOu02kPDq0Takj1", 2, "0", "0"],
    ["UUnl7FMNTtNjiWBROImqyyfNQc83", 2, "0", "0"],
    ["3RZX2bAOKnO2NrEaUnemtMdXxwJ3", 2, "0", "0"],
    ["GS3d9f8XwhaWenhJi0mI9kVBCaI3", 2, "0", "0"],
    ["LeCHZAcynvagldNneJ8tUe1gORJ2", 2, "0", "0"],
    ["MgL9OdiM1fS2KJ8Ct94qhPUKKFJ3", 2, "0", "0"],
    ["ier1zzdcyHVCmsFjO6pSDl4QLkP2", 2, "0", "0"],
    ["wKc1qiXDkLMuVrxXUZG3Q5haHr52", 2, "0", "0"],
  ];

  let i = 1;
  for (const user of users) {
    const [uid, yf_sonuc, yf_puan, yf_ekpuan] = user;
    const data = {
      products: {
        oyun2024: {
          yf_sonuc,
          yf_puan,
          yf_ekpuan,
        },
      },
    };
    await saveUserData(uid, data);
    if (i % 10 === 0) console.log(i + "/" + users.length);
    i++;
  }
};

const getElemeSuccessfulEmails = async () => {
  const allUsers = await getAllUsers();
  const emails = [];
  for (const user of allUsers) {
    const { email, products } = user;
    if (products?.oyun2023?.eleme_sonuc === 1) {
      emails.push(email);
    }
  }
  console.log(emails);
  arrayToCsv(emails, "eleme_successful_emails.csv");
};

//Eleme sonucunda yarı finale hak kazananların listesi
export const getElemeSuccessfulEmailsYf = async () => {
  const allUsers = await getAllUsers();
  const emails = [];

  for (const user of allUsers) {
    const { email, products } = user;
    if (products?.oyun2024?.eleme_sonuc === 1) {
      emails.push(email);
    }
  }
  const csvContent = "data:text/csv;charset=utf-8," + emails.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "eleme_successful_emails.csv");
  document.body.appendChild(link);

  link.click();
};
//yarı final sonucunda finale kalanlar
export const getYfSuccessfulEmailsFn = async () => {
  const allUsers = await getAllUsers();
  const names = [];

  for (const user of allUsers) {
    const { name, products } = user;
    if (products?.oyun2024?.yf_sonuc === 1) {
      names.push(name);
    }
  }

  const bom = "\uFEFF"; // UTF-8 BOM
  const csvContent = bom + names.join("\n");
  const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "Finalist Listesi.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getTopRankHolders = async (season) => {
  const queryOfRanks = query(
    collection(db, "users"),
    where(`products.${season}.final_derecesi`, "<=", 10)
  );
  const querySnapshot = await getDocs(queryOfRanks);
  let allUsers = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const user = doc.data();
    allUsers.push({
      name: user.name,
      birth_year: user.birth_year,
      medal: user.products[season].final_derecesi,
      uid: doc.id,
    });
  });
  return allUsers;
};

export const generateHallOfFames = async (season) => {
  const hallOfFameUsers = await getHallOfFameUsers(season);
  const topRankedUsers = await getTopRankHolders(season);

  for (const user of topRankedUsers) {
    const { uid } = user;
    const isUserInHOF = hallOfFameUsers.find((u) => u.uid === uid);
    if (!isUserInHOF) {
      const ref = doc(collection(db, "hall_of_fames"));

      await setDoc(
        ref,
        {
          user_id: uid,
          season: season,
          name: user.name,
          category: generateCategory(user.birth_year),
          medal: user.medal,
          result: `${generateCategory(user.birth_year)} ${STATUS[user.medal]}`,
        },
        { merge: true }
      );
    }
  }
};

export const getHallOfFameUsers = async (season) => {
  if (!season) {
    console.log("Please provide a season name to get hall of fame users.");
    return [];
  }
  const queryOfHOF = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season)
  );
  const querySnapshot = await getDocs(queryOfHOF);
  let allUHOFusers = [];
  querySnapshot.forEach((doc) => {
    allUHOFusers.push(doc.data());
  });
  return allUHOFusers;
};
export const uploadImageToDb = async (file, uid) => {
  const storageRef = ref(
    storage,
    `2023_public/hall_of_fame/${uid}/profile.jpg`
  );
  const snapshot = await uploadString(storageRef, file, "base64");
  const starsRef = ref(storage, snapshot.ref.fullPath);
  const downloadURL = await getDownloadURL(starsRef);
  return downloadURL;
};

export const addAboutToDb = async (about, season, uid, photoURL) => {
  const queryOfHof = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season),
    where("user_id", "==", uid)
  );
  const querySnapshot = await getDocs(queryOfHof);
  let hofDoc = querySnapshot?.docs?.[0];
  if (!hofDoc) {
    alert("Kullanıcı bulunamadı.");
    return;
  }
  let trimmedAboutString = about.slice(0, 100);
  trimmedAboutString = trimmedAboutString + "...";
  const ref = doc(db, "hall_of_fames", hofDoc.id);
  await setDoc(
    ref,
    {
      about: trimmedAboutString,
      about2: about,
      photo: photoURL,
    },
    { merge: true }
  );
};

export const getHallOfFameUser = async (season, uid) => {
  const queryOfHof = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season),
    where("user_id", "==", uid)
  );
  const querySnapshot = await getDocs(queryOfHof);
  let hofDoc = querySnapshot?.docs?.[0];
  if (!hofDoc) {
    alert("Kullanıcı bulunamadı.");
    return;
  }
  return hofDoc.data();
};

export const writeTokenToDb = async (uid, token) => {
  const ref = doc(db, "users", uid);
  await setDoc(
    ref,
    {
      token_for_concurrent_logins: token,
    },
    { merge: true }
  );
};

export const getConcurrentLoginToken = async (uid) => {
  const ref = doc(db, "users", uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    return docSnap.data().token_for_concurrent_logins;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};
